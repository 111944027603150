import React, {useCallback} from 'react';
import {Navigate, useLocation} from 'react-router-dom-v5-compat';
import {connect} from 'react-redux';

const ProtectedRoute = ({roles, isAuthenticated, token, user, children}) => {
    let location = useLocation();

    const renderOnboarding = useCallback(status => {
        switch (status) {
            case 0:
                return <Navigate to='/onboarding/region' />;
            case 1:
                return <Navigate to='/onboarding/area' />;
            case 2:
                return <Navigate to='/onboarding/branch' />;
            case 3:
                return <Navigate to='/onboarding/warehouse' />;
            case 4:
                return <Navigate to='/onboarding/route' />;
            case 5:
                return <Navigate to='/onboarding/product-multipack' />;
            case 6:
                return <Navigate to='/onboarding/product-category' />;
            case 7:
                return <Navigate to='/onboarding/product-subcategory' />;
            case 8:
                return <Navigate to='/onboarding/product' />;
            case 9:
                return <Navigate to='/onboarding/product-price' />;
            case 10:
                return <Navigate to='/onboarding/customer' />;
            case 11:
                return <Navigate to='/onboarding/custom-document-code' />;
            case 12:
                return <Navigate to='/onboarding/company-settings' />;
            case 13:
                return <Navigate to='/onboarding/users' />;
            default:
                break;
        }
    }, []);

    if (!isAuthenticated || !user) {
        return <Navigate to='/login' state={{from: location}} replace />;
    }

    if (
        user.onboarding_status != 14 &&
        !window.location.pathname.includes('onboarding')
    ) {
        return renderOnboarding(user.onboarding_status);
    }

    if (!roles || roles.includes(user.role)) {
        return children;
    }

    return <Navigate to='/404' replace={true} />;
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
