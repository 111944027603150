import React, {useState, useEffect, Suspense, lazy} from 'react';
import {Button, Table, Row, message, Popconfirm, Typography} from 'antd';
import {getApiWithAuthToken, updateApiWithAuthToken} from '../../../api';
// import {
//     useColumnSearchProps,
//     handleDeleteClick,
//     handleTableChange,
// } from '../../../helpers/table';
import ProtectedComponent from '../../ProtectedComponent';
const CustomerLocationMapView = lazy(() => import('./CustomerLocationMap'));

const CustomerLocation = () => {
    const [customerlocation, setCustomerLocation] = useState([]);
    const [selectedCustomerMap, setSelectedCustomerMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        page: 1,
    });

    const handleClickMap = (title, record) => {
        if (title === 'old') {
            setSelectedCustomerMap(record.old_location);
        } else {
            setSelectedCustomerMap(record.requested_location);
        }
        showDrawer();
    };

    //Drawer Open function
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerVisible(false);
    };

    //Customerlocation get function
    const getCustomerLocationList = (qp = queryParams) => {
        setLoading(true);
        getApiWithAuthToken(`/api/v1/admin/customerLocationUpdate`).then(
            res => {
                if (!res.error && res.data?.length) {
                    setTotalCount(res.data.total_count);
                    setCustomerLocation(
                        res.data.map(each => ({
                            id: each.id,
                            key: each.id,
                            salesman_name: each.salesman_name,
                            salesman_code: each.salesman_code,
                            customer_name: each.customer_name,
                            customer_code: each.customer_code,
                            requested_location: {
                                latitude: each.request_latitude,
                                longitude: each.request_longitude,
                            },
                            old_location: {
                                latitude: each.old_latitude,
                                longitude: each.old_longitude,
                            },
                            status: each.status,
                        })),
                    );
                } else if (res.data === null) {
                    setCustomerLocation([]);
                } else {
                    if (res.errors) {
                        Object.values(res.errors).map(each =>
                            message.error(each.msg),
                        );
                    } else {
                        message.error({
                            content: 'Network Error!',
                        });
                    }
                    setCustomerLocation([]);
                }
                setLoading(false);
            },
        );
        setLoading(false);
    };

    // On load
    useEffect(() => {
        getCustomerLocationList(queryParams);
    }, []);

    // Approve function
    const handleApprove = record => {
        updateApiWithAuthToken(
            `/api/v1/admin/customerLocationUpdate?customer_code=${record.customer_code}`,
        ).then(res => {
            if (!res.error) {
                setTimeout(() => {
                    message.success({content: res.msg, key: 'form'});
                }, 500);
                getCustomerLocationList(queryParams);
            } else {
                setTimeout(() => {
                    if (res.errors.length) {
                        res.errors.map(each => {
                            message.error({
                                content: each.msg,
                                key: 'form',
                            });
                            return null;
                        });
                    } else {
                        message.error({content: res.errors, key: 'form'});
                    }
                }, 500);
            }
        });
    };

    // Cancel function
    const handleCancelClick = record => {
        // setCustomerList(record)
    };

    const formatCoordinates = (coordinate, direction) => {
        const absolute = Math.abs(coordinate);
        const degrees = Math.floor(absolute);
        const minutes = Math.floor((absolute - degrees) * 60);
        const seconds = Math.round((absolute - degrees - minutes / 60) * 3600);

        return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
    };

    const columns = [
        {
            title: 'Salesman Code',
            dataIndex: 'salesman_code',
            key: 'salesman_code',
        },
        {
            title: 'Salesman Name',
            dataIndex: 'salesman_name',
            key: 'salesman_name',
        },
        {
            title: 'Customer Code',
            dataIndex: 'customer_code',
            key: 'customer_code',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },

        {
            title: 'Old Location',
            dataIndex: 'old_location',
            render: (_, record) => (
                <ProtectedComponent
                    permissionName='Customer'
                    actionName='update'
                >
                    <Button
                        onClick={() => handleClickMap('old', record)}
                        style={{fontSize: '12px'}}
                    >
                        {`${formatCoordinates(
                            record.old_location.latitude,
                            'N',
                        )}, ${formatCoordinates(record.old_location.longitude, 'W')} `}
                    </Button>
                </ProtectedComponent>
            ),
        },
        {
            title: 'Requested Location',
            dataIndex: 'requested_location',
            render: (_, record) => (
                <ProtectedComponent
                    permissionName='Customer'
                    actionName='update'
                >
                    <Button
                        onClick={() => handleClickMap('new', record)}
                        style={{fontSize: '12px'}}
                    >
                        {`${formatCoordinates(
                            record.requested_location.latitude,
                            'N',
                        )}, ${formatCoordinates(record.requested_location.longitude, 'W')}`}
                    </Button>
                </ProtectedComponent>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (_, record) => (
                <>
                    <ProtectedComponent
                        permissionName='Customer'
                        actionName='approve'
                    >
                        <Button
                            size='small'
                            onClick={() => handleApprove(record)}
                            type='primary'
                        >
                            {`Approve`}
                        </Button>
                    </ProtectedComponent>
                </>
            ),
        },
    ];

    return (
        <>
            <Row
                justify='space-between'
                style={{
                    margin: '24px 0',
                }}
            >
                <Typography.Title
                    level={3}
                    style={{
                        marginBottom: '0',
                    }}
                >
                    {`Customer Location Updates`}
                </Typography.Title>
            </Row>
            <ProtectedComponent permissionName='Customer' actionName='read'>
                <Table
                    scroll={{
                        x: 0,
                    }}
                    dataSource={customerlocation}
                    columns={columns}
                    loading={loading}
                    // onChange={handleTableChange(
                    //     queryParams,
                    //     setQueryParams,
                    //     getCustomerLocationList
                    // )}
                    pagination={{
                        showSizeChanger: true,
                        total: totalCount,
                        current: queryParams.page,
                        pageSize: queryParams.limit,
                        size: 'default',
                        showTotal: (total, range) =>
                            `${range[0]} - ${range[1]} of ${total} items`,
                    }}
                />
            </ProtectedComponent>
            {Object.keys(selectedCustomerMap).length !== 0 ? (
                <Suspense fallback={'Loading...'}>
                    <CustomerLocationMapView
                        handleDrawerClose={handleDrawerClose}
                        isDrawerVisible={isDrawerVisible}
                        selectedMap={selectedCustomerMap}
                        formatCoordinates={formatCoordinates}
                    />
                </Suspense>
            ) : (
                ''
            )}
        </>
    );
};

export default CustomerLocation;
