import React, {useEffect, useState} from 'react';
import moment from 'moment';
// import FontRegular from '../../../assets/fonts/Questrial-Regular.ttf';
import FontRegular from '../../../assets/fonts/OpenSans-Bold.ttf';
import NoImage from '../../../assets/images/no-image.png';
import FontArabic from '../../../assets/fonts/Zain-Bold.ttf';
import {
    Image,
    Text,
    View,
    Page,
    Document,
    StyleSheet,
} from '@react-pdf/renderer';
import {getApiWithAuthToken} from 'api';
import {Font} from '@react-pdf/renderer';
// import QRCodeComponent from '.';

const CollectionPrint = ({InvoiceData}) => {
    const [data, setData] = useState('');
    const Logo = localStorage.getItem('psLogo');

    Font.register({
        family: 'Regular',
        src: FontRegular,
    });

    Font.register({
        family: 'Amiri',
        src: FontArabic,
    });

    useEffect(() => {
        getApiWithAuthToken('/api/v1/admin/printSettings').then(res => {
            setData(res.data);
            console.log(res);
        });
    }, []);
    // Register Amiri font

    const styles = StyleSheet.create({
        page: {
            fontSize: 11,
            paddingTop: 20,
            paddingLeft: 40,
            paddingRight: 40,
            lineHeight: 1.5,
            flexDirection: 'column',
        },
        table: {
            borderTopWidth: 2,
            borderRightWidth: 2,
            borderLeftWidth: 2,
            borderColor: 'black',
            marginTop: 10,
            marginBottom: 10,
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCell: {
            flex: 1,
            padding: 8,
            borderRightWidth: 2,
            // borderWidth: 2,
            // borderColor: 'black',
        },
        headerCell: {},
        signatureContainer: {
            marginTop: 120,
        },

        hr: {
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            marginBottom: 2,
            width: 80,
        },

        spaceBetween: {
            flex: 1,
            flexDirection: 'row',

            justifyContent: 'space-between',
        },

        titleContainer: {flexDirection: 'row', marginTop: 24},

        logo: {
            width: 100, // Adjust as needed
            height: 50, // Adjust as needed
        },

        reportTitle: {fontSize: 16, textAlign: 'center'},

        addressTitle: {fontSize: 11, fontStyle: 'bold'},

        invoice: {fontFamily: 'Regular', fontWeight: 'bold', fontSize: 9},
        invoiceH: {
            fontFamily: 'Regular',
            fontWeight: 'bold',
            fontSize: 12,
        },
        invoiceV: {
            fontWeight: 'bold',
            fontSize: 10,
        },
        invoice1: {
            fontWeight: 'bold',
            fontSize: 9,
            marginTop: 15,
            fontFamily: 'Regular',
        },

        invoiceNumber: {fontSize: 11, fontWeight: 'bold'},

        address: {fontWeight: 400, fontSize: 10},
        arabicText: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 10,
            fontWeight: 'bold',
        },
        arabicText1: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 9,
            textAlign: 'right',
            paddingBottom: 0,
        },
        arabicText2: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 14,
            textAlign: 'right',
            paddingBottom: 0,
        },

        headerItem: {
            fontSize: 8.1,
            fontFamily: 'Regular',
            fontWeight: 'bold',
            paddingLeft: '3px',
            borderBottom: '2px solid black',
            justifyContent: 'center',
        },
        borderRight: {
            borderRightWidth: 2,
            borderRightColor: 'black',
        },
        headerItem1: {
            fontSize: 10,
            fontWeight: 'bold',
            padding: '10px',

            height: 30,
        },
        headerItem2: {
            fontSize: 8,
            fontWeight: 'bold',
        },

        headerItemMain: {
            width: '50%',
            height: 40, // Set a fixed height for each row
            justifyContent: 'center',
        },
        headerItemValue: {
            fontSize: 10,
            fontWeight: 'bold',
            paddingLeft: '3px',
            borderBottom: '2px solid black',
            width: '50%',
            borderLeft: '2px solid black',
        },
        headerItemSub: {
            borderRight: '2px solid black',
        },

        theader: {
            fontFamily: 'Regular',
            fontSize: 15,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
        },

        total: {
            fontSize: 9,
            paddingTop: 4,
            paddingLeft: 7,
            flex: 1.5,
            borderColor: 'whitesmoke',
            borderBottomWidth: 2,
        },

        tbody2: {flex: 2, borderRightWidth: 1},
    });
    const salemanArabic = '  بائع';
    const officeArabic = 'مكتب';
    const collection = 'تجميع - إيصال';
    const CustomerName = 'اسم الزبون';
    const CustomerCode = 'كود العميل';
    const DocumentDate = 'تاريخ المستند';
    const DocumentType = 'نوع المستند';
    const DocumentNO = 'رقم المستند';
    const email = 'بريد إلكتروني';
    const PageNo = '1 من 1 الصفحة';

    const Address = () => (
        <>
            <View style={styles.titleContainer}>
                <View style={styles.spaceBetween}>
                    <View>
                        <Text style={styles.invoiceH}>
                            {data?.company_name}
                        </Text>
                        <Text style={styles.invoiceH}>
                            {data?.company_sector}
                        </Text>
                        <Text style={styles.invoiceH}>
                            CR:{data?.company_CR}
                        </Text>
                        <Text style={styles.invoiceH}>
                            {InvoiceData?.tax_1}:{data?.company_vat}
                        </Text>
                        <Text style={styles.invoice}>
                            {data?.company_website}
                        </Text>
                        <Text style={styles.invoice}>
                            Email:{data?.company_email}
                        </Text>

                        <Text style={[styles.invoice, {paddingTop: '15px'}]}>
                            Salesman: 123233
                        </Text>
                        <Text style={styles.invoice}>Office: 123323</Text>
                    </View>
                    <View style={{flexDirection: 'column'}}>
                        <View style={{alignItems: 'center'}}>
                            {Logo ? (
                                <Image style={styles.logo} src={Logo} />
                            ) : (
                                <Image style={styles.logo} src={NoImage} />
                            )}
                        </View>
                        <View
                            style={{
                                alignItems: 'center',
                                marginTop: 45,
                            }}
                        >
                            <Text style={styles.invoice}>
                                Bank Name 1:{data?.bank_name1}
                            </Text>
                            <Text style={styles.invoice}>
                                Account No:{data?.bank_account_no1}
                            </Text>
                            {data?.bank_name2 && data?.bank_account_no1 && (
                                <>
                                    <Text style={styles.invoice}>
                                        Bank Name 2:
                                        {data?.bank_name2}
                                    </Text>
                                    <Text style={styles.invoice}>
                                        Account No:
                                        {data?.bank_account_no2}
                                    </Text>
                                </>
                            )}
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Text style={styles.arabicText2}>
                            {' '}
                            {data?.company_name_ar}
                        </Text>

                        <Text style={styles.arabicText2}>
                            {data?.company_sector_ar}
                        </Text>

                        <Text style={styles.arabicText2}>
                            {' '}
                            {data?.company_CR_ar}
                        </Text>

                        <Text style={styles.arabicText1}>
                            {' '}
                            {data?.company_vat_ar}
                        </Text>

                        <Text style={styles.invoice}>
                            {' '}
                            {data?.company_website}
                        </Text>

                        <View style={{flexDirection: 'row'}}>
                            <Text style={styles.invoice}>
                                {' '}
                                {data?.company_email}:{' '}
                            </Text>{' '}
                            <Text style={styles.arabicText1}> {email}</Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                paddingTop: '15px',
                            }}
                        >
                            <Text style={styles.invoice}> 1681616: </Text>{' '}
                            <Text style={styles.arabicText1}>
                                {' '}
                                {salemanArabic}
                            </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={styles.invoice}>17291782: </Text>{' '}
                            <Text style={styles.arabicText1}>
                                {' '}
                                {officeArabic}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>

            <View
                style={{borderBottom: '3px solid black', paddingTop: 5}}
            ></View>
        </>
    );

    const TableHead = () => (
        <View
            style={{
                marginTop: 10,
                border: '2px solid black',
            }}
        >
            <View
                style={[
                    styles.theader,
                    {
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                ]}
            >
                <Text>COLLECTION - RECEIPT</Text>
                <Text style={[styles.arabicText, {fontSize: 20}]}>
                    {collection}
                </Text>
            </View>
            <View style={{borderBottom: '2px solid black'}}></View>
            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                        {flex: 1.19},
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>Customer name</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                        {flex: 5},
                    ]}
                >
                    <Text style={{textAlign: 'left'}}>
                        {InvoiceData?.customer_name}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        {flex: 1.22},
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {CustomerName}
                    </Text>
                </View>
            </View>

            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>Customer Code</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                        {textAlign: 'center'},
                    ]}
                >
                    {/* Replace 'invoiceNumberValue' with the actual value */}
                    <Text>{InvoiceData?.customer_code}</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {CustomerCode}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>Document Number</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                        {textAlign: 'center'},
                    ]}
                >
                    {/* Replace 'invoiceNumberValue' with the actual value */}
                    <Text>{InvoiceData?.collection_code}</Text>
                </View>
                <View
                    style={[styles.headerItem, styles.headerItemMain, styles]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {DocumentNO}
                    </Text>
                </View>
            </View>
            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItemMain,
                        styles.borderRight,
                        {
                            fontSize: 8.1,
                            fontFamily: 'Regular',
                            fontWeight: 'bold',
                        },
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>Document Date</Text>
                </View>
                <View
                    style={[
                        styles.headerItemMain,
                        styles.borderRight,
                        {
                            fontSize: 8.1,
                            fontFamily: 'Regular',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        },
                    ]}
                >
                    <Text style={{paddingLeft: '2px'}}>
                        {InvoiceData?.sales_date}
                    </Text>
                    <Text style={{paddingLeft: '2px'}}>
                        {InvoiceData?.sales_time}
                    </Text>
                </View>
                <View style={[styles.headerItemMain, styles.borderRight]}>
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {DocumentDate}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItemMain,
                        styles.borderRight,
                        {
                            fontSize: 8.1,
                            fontFamily: 'Regular',
                            fontWeight: 'bold',
                        },
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>Document Type</Text>
                </View>
                <View
                    style={[
                        styles.headerItemMain,
                        styles.borderRight,
                        {
                            fontSize: 8.1,
                            fontFamily: 'Regular',
                            fontWeight: 'bold',
                        },
                    ]}
                >
                    {/* Replace 'invoiceNumberValue' with the actual value */}
                    <Text style={{textAlign: 'center'}}>Credit</Text>
                </View>
                <View style={[styles.headerItemMain, styles]}>
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {DocumentType}
                    </Text>
                </View>
            </View>
        </View>
    );

    // Define your table header with both Arabic and English names ..second table
    const TableHeader = () => (
        <View style={styles.tableRow}>
            <View style={[styles.tableCell, styles.headerCell]}>
                <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                    رقم
                </Text>
                <Text
                    style={{
                        fontSize: 8.1,
                        fontFamily: 'Regular',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    No
                </Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell]}>
                <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                    نوع
                </Text>
                <Text
                    style={{
                        fontSize: 7,
                        fontFamily: 'Regular',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    {' '}
                    Type
                </Text>
            </View>
            <View style={[{flex: 1, padding: 8}, styles.headerCell]}>
                <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                    المبلغ المحصل
                </Text>
                <Text
                    style={{
                        fontSize: 8.1,
                        fontFamily: 'Regular',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    {' '}
                    Collected Amount
                </Text>
            </View>
        </View>
    );

    // Define your table rows with dynamic data
    // Define your table rows with dynamic data
    const TableRows = () => (
        <View>
            {InvoiceData?.payment_types?.map((item, index) => (
                <>
                    <View key={index} style={styles.tableRow}>
                        <View key={index} style={[styles.tableCell]}>
                            <Text
                                style={{
                                    fontSize: 7,
                                    fontSize: 8.1,
                                    fontFamily: 'Regular',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {index + 1}
                            </Text>
                        </View>
                        <View key={index} style={[styles.tableCell]}>
                            <Text
                                style={{
                                    fontSize: 7,
                                    fontSize: 8.1,
                                    fontFamily: 'Regular',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {item.payment_type_name}
                            </Text>
                        </View>
                        <View key={index} style={[{flex: 1, padding: 8}]}>
                            <Text
                                style={{
                                    fontSize: 7,
                                    fontSize: 8.1,
                                    fontFamily: 'Regular',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {data?.currency_symbol} &nbsp; {item.paid}
                            </Text>
                        </View>
                    </View>
                    <View
                        key={index}
                        style={{borderBottom: '2px solid black'}}
                    ></View>
                </>
            ))}
        </View>
    );

    const Signature = () => (
        <View style={styles.signatureContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    {/* Small HR for customer signature */}
                    <View style={styles.hr} />
                    {/* Arabic name for customer signature */}
                    <Text style={styles.arabicText}>توقيع العميل</Text>
                    {/* Customer signature */}
                    <Text style={styles.invoice1}>Customer Signature:</Text>
                </View>
                {/* Right end */}
                <View>
                    {/* Small HR for salesman signature */}
                    <View style={styles.hr} />
                    {/* Arabic name for salesman signature */}
                    <Text style={styles.arabicText}>توقيع البائع</Text>
                    {/* Salesman signature */}
                    <Text style={styles.invoice1}>Salesman Signature:</Text>
                    <Text style={styles.invoice1}>
                        {InvoiceData?.salesman_name}
                    </Text>
                    <Text style={styles.invoice1}>
                        Route Code : {InvoiceData?.route_code}
                    </Text>
                </View>
            </View>
        </View>
    );

    // Combine header and rows to form the table
    const DynamicTable = () => (
        <View style={styles.table}>
            <TableHeader />
            <View style={{borderBottom: '2px solid black'}}></View>
            <TableRows />
        </View>
    );

    const Footer = () => (
        <View
            style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                textAlign: 'center',
                fontSize: 8,
                fontFamily: 'Regular',
                fontWeight: 'bold',
                padding: 10,
                borderTop: '2px solid black',
            }}
        >
            <View style={styles.spaceBetween}>
                <View>
                    <Text>Page 1 of 1</Text>
                </View>
                <View>
                    <Text>
                        {moment
                            .utc(InvoiceData?.sales_date)
                            .local()
                            .format('YYYY-MM-DD')}

                        {moment
                            .utc(InvoiceData?.sales_time, 'HH:mm:ss')
                            .local()
                            .format('HH:mm:ss')}
                    </Text>
                </View>
                <View>
                    <Text style={styles.arabicText}>{PageNo}</Text>
                </View>
            </View>
        </View>
    );

    return (
        <Document title={`C.${InvoiceData?.collection_code}`} producer='arun'>
            <Page size='A4' style={styles.page}>
                <Address />
                <TableHead />
                <DynamicTable />
                <Signature />
                <Footer />
            </Page>
        </Document>
    );
};

export default CollectionPrint;
