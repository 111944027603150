import React from 'react';
import {
    Layout,
    Button,
    Avatar,
    Row,
    Space,
    Dropdown,
    Typography,
    Image,
} from 'antd';
import VF_logo from '../../assets/images/VF_logo.png';
import VanforceLogo from '../../assets/images/VanforceLogo.png';
import {logout} from '../../actions/authAction';
import {
    UserOutlined,
    MenuOutlined,
    SettingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom-v5-compat';

const {Header} = Layout;
const {Text} = Typography;

const HeaderView = ({logout, user, toggleSider, disableSider}) => {
    const navigate = useNavigate();

    const location = useLocation();

    const isCompanySettingsPage =
        location.pathname.startsWith('/company-settings');

    const onClick = ({key}) => {
        console.log('users', user);

        if (key === 'item-2') {
            logout();
        }
    };

    const items = [
        {
            label: <Link to={'/company-settings'}> Company Settings</Link>,
            icon: (
                <SettingOutlined
                    style={{
                        fontSize: '20px',

                        fontWeight: 'bold',
                        color: '#1cb2bb',
                    }}
                />
            ),
            key: 'item-1',
        },
        {
            type: 'divider',
        },
        {
            label: 'Log Out',
            icon: (
                <LogoutOutlined
                    style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#1cb2bb',
                    }}
                />
            ),
            key: 'item-2',
        },
    ];

    return (
        <Header
            className='site-layout-header'
            style={{padding: '0 16px', background: 'white'}}
        >
            <Row justify='space-between'>
                <div>
                    {!disableSider ? (
                        <Button
                            className='menu'
                            icon={<MenuOutlined />}
                            onClick={() => toggleSider()}
                            style={{marginRight: '24px'}}
                        />
                    ) : null}

                    {isCompanySettingsPage ? (
                        <Link to='/'>
                            <span className='logo'>
                                <Image
                                    width={35}
                                    style={{
                                        height: '35px',
                                        cursor: 'pointer',
                                    }}
                                    src={VanforceLogo}
                                    alt='vanforces logo'
                                    preview={false}
                                />
                                <span
                                    style={{
                                        color: '#364753',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        marginLeft: '12px',
                                    }}
                                >
                                    Settings
                                </span>
                            </span>
                        </Link>
                    ) : (
                        <Link to='/'>
                            <span className='logo'>
                                <Image
                                    style={{
                                        height: '28px',
                                        cursor: 'pointer',
                                    }}
                                    src={VF_logo}
                                    alt='vanforces logo'
                                    onClick={() => navigate('/')}
                                    preview={false}
                                />
                            </span>
                        </Link>
                    )}
                </div>

                <Space
                // size={30}
                // style={{marginRight: '10px'}}
                >
                    <Text style={{marginRight: '10px'}}>{user?.name}</Text>
                    <Dropdown
                        menu={{
                            items,
                            onClick,
                        }}
                        trigger={['click']}
                    >
                        {user?.profile_image.String !== '' ? (
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '40px',
                                    backgroundImage: `url(${user?.profile_image?.String})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    cursor: 'pointer',
                                }}
                            ></div>
                        ) : (
                            <Avatar
                                size={32}
                                icon={<UserOutlined />}
                                style={{cursor: 'pointer'}}
                            />
                        )}
                    </Dropdown>
                </Space>
            </Row>
        </Header>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {logout})(HeaderView);
