import React, {useEffect, useState} from 'react';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {useHistory} from 'react-router-dom';
import InvoicePDF from './QuotationPrint';
import {Modal, Button} from 'antd';

function InvoiceView({Invoice}) {
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const history = useHistory();

    useEffect(() => {
        console.log(Invoice);
        if (Invoice) {
            setShowDownloadButton(true); // Show the download button when Invoice data is available
            setModalIsVisible(true); // Open the modal when Invoice data is available
        }
    }, [Invoice]);

    useEffect(() => {
        if (isDownloaded) {
            setModalIsVisible(false); // Close the modal after downloading
            history.push('/quotations-summary'); // Replace with your target page path
        }
    }, [isDownloaded, history]);

    const handleDownloadClick = () => {
        setIsDownloaded(true);
    };

    const handleCancel = () => {
        setModalIsVisible(false);
    };

    return (
        <div>
            <Modal
                title='Download Invoice'
                open={modalIsVisible}
                onCancel={handleCancel}
                footer={null}
            >
                {showDownloadButton && (
                    <div style={{textAlign: 'center', marginTop: '20px'}}>
                        <Button onClick={handleDownloadClick} type='primary'>
                            <PDFDownloadLink
                                document={<InvoicePDF InvoiceData={Invoice} />}
                                fileName='QuoteInvoice.pdf'
                                style={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                }}
                            >
                                {({loading, error}) =>
                                    loading
                                        ? 'Preparing document...'
                                        : 'Download Invoice'
                                }
                            </PDFDownloadLink>
                        </Button>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default InvoiceView;
// import React, {useEffect,useState} from "react";
// import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
// import InvoicePDF from "./QuotationPrint";

// function InvoiceView({Invoice}) {
//   const containerStyle = {
//     width: "800px", // Set your desired width
//     height: "600px", // Set your desired height
//     margin: "0 auto",
//   };
//   useEffect(() => {
//     console.log(Invoice)
//   },[Invoice])

//   return (
//     <div>
//       <h2 style={{ textAlign: 'center' }}>Quotation Invoice</h2>
//       <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

//         <div style={containerStyle}>
//           <PDFViewer style={{ width: '100%', height: '100%' }}>
//             <InvoicePDF InvoiceData= {Invoice} />
//           </PDFViewer>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default InvoiceView;
