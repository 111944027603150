
import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import InvoicePDF from './InvoicePrint';
import { getApiWithAuthToken } from 'api';
import { Modal, Button } from 'antd';

function InvoiceView({ Invoice }) {
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);
      const [data, setData] = useState();
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const history = useHistory();
       useEffect(() => {
        getApiWithAuthToken('/api/v1/admin/printSettings').then(res => {
            setData(res.data);
        });
    }, []);

    useEffect(() => {
        if (Invoice) {
            setShowDownloadButton(true); 
            setModalIsVisible(true); 
        }
    }, [Invoice,data]);

    useEffect(() => {
        if (isDownloaded) {
            setModalIsVisible(false); // Close the modal after downloading
            history.push('/sales-summary'); // Navigate after download
        }
    }, [isDownloaded, history]);

    const handleDownloadClick = () => {
        setIsDownloaded(true);
    };

    const handleCancel = () => {
        setModalIsVisible(false);
        history.push('/sales-summary'); 
    };

    return (
        <div>
            <Modal
                title='Invoice Preview'
                open={modalIsVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="download" type="primary" onClick={handleDownloadClick}>
                        <PDFDownloadLink
                            document={<InvoicePDF InvoiceData={Invoice} data={data} />}
                            fileName='invoice.pdf'
                            style={{
                                textDecoration: 'none',
                                color: '#fff',
                            }}
                        >
                            {({ loading }) => (loading ? 'Preparing document...' : 'Download Invoice')}
                        </PDFDownloadLink>
                    </Button>
                ]}
                width={600} 
               
            >
                <PDFViewer style={{ width: '100%', height: '600px' }}>
                    <InvoicePDF InvoiceData={Invoice} data={data} />
                </PDFViewer>
            </Modal>
        </div>
    );
}

export default InvoiceView;
