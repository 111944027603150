import React, {useState, useEffect, useContext, useRef, lazy} from 'react';

import {
    Input,
    Form,
    Button,
    Table,
    Row,
    Space,
    message,
    Descriptions,
    Typography,
    Select,
    DatePicker,
    Tooltip,
    Modal,
    Col,
} from 'antd';
import {LeftOutlined} from '@ant-design/icons';
import moment from 'moment/moment';
import {useHistory} from 'react-router-dom';

import {getApiWithAuthToken, postApiWithAuthToken} from 'api';

import InvoiceView from './InvoiceView';
import CollectionInvoiceView from './CollectionView';

import {
    MinusCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import {convertLocalToUTC} from 'helpers/utcTime';

const SalesSummaryModal = lazy(() => import('./SalesSummaryModal'));

const {Text} = Typography;
const {TextArea} = Input;
const {Option} = Select;

let text = Date.now();

const NewSaleEntry = () => {
    const [form] = Form.useForm();
    const history = useHistory();

    //Table States

    const [route, setRoute] = useState();
    const [customer, setCustomer] = useState({});

    const [multiSalesTypeAllowed, setMultiSalesTypeAllowed] = useState(false);
    const [lockedSalesType, setLockedSalesType] = useState(null);

    const [routeCode, setRouteCode] = useState();
    const [salesman, setSalesman] = useState([]);
    const [selectedSalesman, setSelectedSalesman] = useState(null);
    const [routeName, setRouteName] = useState('');
    const [routeDet, setRouteDet] = useState([]);
    const [transType, setTransType] = useState();
    const [routeEInvoiceEnable, setRouteEInvoiceEnable] = useState(false);
    const [eInvoiceModalVisible, setEInvoiceModalVisible] = useState(false);
    const [grSelected, setGrSelected] = useState(false);
    const [selectedSalesType, setSelectedSalesType] = useState(null);
    const [billingReference, setBillingReference] = useState(null);
    const [grSalesDetails, setGrSalesDetails] = useState([]);
    const [partialInvoice, setPartialInvoice] = useState(false);
    const [grQuantityValidation, setGrQuantityValidation] = useState(true);
    const [amendSameProduct, setAmendSameProduct] = useState(false);

    const [transName, setTransName] = useState('');
    const [confirmFinal, setConfirmFinal] = useState(false);

    const [vatRegNo, setVatRegNo] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [collectionData, setCollectionData] = useState({});
    const [currencyDet, setCurrencyDet] = useState([]);

    const [currency, setCurrency] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedCurrencyName, setSelectedCurrencyName] = useState('');
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('');

    const [roundingRule, setRoundingRule] = useState('');
    const [roundingPrecision, setRoundingPrecision] = useState(0);
    const [currencyId, setCurrencyId] = useState();

    const [startTime, setStartTime] = useState(null);

    const [customerDet, setCustomerDet] = useState([]);
    const [salesType, setSalesType] = useState([]);
    const [remarks, setRemarks] = useState('');

    const [confirm, setConfirm] = useState(false);
    const [custData, setCustData] = useState(true);

    const [productId, setSelectProductId] = useState();
    const [editable, setEditable] = useState(false);
    const [collectionModalVisible, setCollectionModalVisible] = useState(false);
    const [collectionFinal, setCollectionFinal] = useState(false);

    const [requestData, setRequestData] = useState(null);

    const showEInvoiceModal = () => {
        setEInvoiceModalVisible(true);
    };

    const handleEinvoiceModalCancel = () => {
        setEInvoiceModalVisible(false);
    };

    //get vat and vat registration details
    useEffect(() => {
        if (customer) {
            try {
                getApiWithAuthToken(`/api/v1/admin/customers/${customer.id}`)
                    .then(res => {
                        if (!res.error && res.data) {
                            const {
                                rounding_rule,
                                rounding_precision,
                                def_currency,
                                def_currency_id,
                            } = res.data;
                            setRoundingRule(rounding_rule);
                            setRoundingPrecision(rounding_precision);
                            setVatRegNo(res.data.vat_reg_no);
                            setCurrency(def_currency);
                            setCurrencyId(def_currency_id);
                            setTransType(res.data.transaction_types);

                            // Set initial currency details
                            const initialCurrency = currencyDet.find(
                                currency => currency.id === def_currency_id,
                            );
                            if (initialCurrency) {
                                setSelectedCurrency(initialCurrency.id);
                                setSelectedCurrencyName(initialCurrency.name);
                                setSelectedCurrencyCode(initialCurrency.code);
                            }
                        }
                    })
                    .catch(error => {});
            } catch (error) {}
        }
    }, [customer, currencyDet]);

    //company Details

    function getCompanyDetails() {
        getApiWithAuthToken('/api/v1/admin/company').then(res => {
            if (res.data) {
                // Assuming multi_sales_type_in_single is part of the response
                setMultiSalesTypeAllowed(
                    res.data?.multi_sales_type_in_single ?? false,
                );

                // Assuming  amend_same_prod is part of the response
                setAmendSameProduct(res.data?.amend_same_prod ?? false);
            } else {
                setMultiSalesTypeAllowed(false);
                setAmendSameProduct(false);
            }
        });
    }

    //currency List
    function getCurrency() {
        getApiWithAuthToken('/api/v1/admin/companyCurrencies').then(res => {
            if (res.data?.length > 0) {
                setCurrencyDet(res.data);
            } else {
                setCurrencyDet([]);
            }
        });
    }

    // final confirm
    const FinalSubmit = async () => {
        try {
            // Collect values from the form items
            const formData = form.getFieldsValue();

            let formattedDataSource = [];

            const formattedDataSourceFinal = dataSource.reduce((acc, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.quantity || 0);
                const vatPercentage = parseFloat(
                    item.tax_perc > 0 ? item.tax_perc : 0 || 0,
                );
                const discount = parseFloat(item.discount || 0);
                const grossAmount = salePrice * quantity;
                const totalTaxableAmount = grossAmount - discount;
                const vatAmount = totalTaxableAmount * vatPercentage;
                const netAmount = totalTaxableAmount + vatAmount;
                const discountPercentage = discount / grossAmount;

                const formattedItem = {
                    ...item,
                    product: item.product.id,
                    product_name: item.product.name,
                    product_code: item.product.code,
                    multipack_id: item.multipack[0]?.id,
                    multipack_code: item.multipack[0]?.code,
                    multipack_name: item.multipack[0]?.name,
                    base_multipack_name: item.multipack[0]?.base_multipack_name,
                    base_multipack_id: item.multipack[0]?.base_multipack_id,
                    base_multipack_code: item.multipack[0]?.base_multipack_code,
                    multiplier: item.multipack[0]?.multiplier,
                    tax_total: roundNumber(
                        vatAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    gross_amount: roundNumber(
                        grossAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    net_amount: roundNumber(
                        netAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    total_taxable_amount: roundNumber(
                        totalTaxableAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    discount_perc: roundNumber(
                        discountPercentage,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                };

                if (amendSameProduct) {
                    const key = `${formattedItem.product}_${formattedItem.multipack_id}_${formattedItem.sales_type}`;

                    if (acc[key]) {
                        // If an item with the same key exists, update its quantities and amounts
                        acc[key].quantity += formattedItem.quantity;
                        acc[key].gross_amount += formattedItem.gross_amount;
                        acc[key].net_amount += formattedItem.net_amount;
                        acc[key].total_taxable_amount +=
                            formattedItem.total_taxable_amount;
                        acc[key].tax_total += formattedItem.tax_total;
                        acc[key].discount += formattedItem.discount;

                        // Recalculate discount percentage
                        acc[key].discount_perc = roundNumber(
                            acc[key].discount / acc[key].gross_amount,
                            roundingRule,
                            roundingPrecision + 1,
                        );
                    } else {
                        // If no item with the same key exists, add the new item to the accumulator
                        acc[key] = formattedItem;
                    }
                } else {
                    // If amendProduct is false, just add the item to the accumulator without combining
                    acc[formattedItem.id] = formattedItem;
                }

                return acc;
            }, {});

            // Convert the object back to an array
            formattedDataSource = Object.values(formattedDataSourceFinal);

            // const totalTaxable = formattedDataSource.reduce(
            //     (total, item) =>
            //         total + parseFloat(item.total_taxable_amount || 0),
            //     0
            // );
            const totalTaxable = formattedDataSource.reduce((total, item) => {
                const taxableAmount = parseFloat(
                    item.total_taxable_amount || 0,
                );

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + taxableAmount;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - taxableAmount;
                }

                return total;
            }, 0);

            const grossAmount = formattedDataSource.reduce((total, item) => {
                const grossAmountValue = parseFloat(item.gross_amount || 0);

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + grossAmountValue;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - grossAmountValue;
                }

                return total;
            }, 0);

            const totalTax = formattedDataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.quantity || 0);
                const discount = parseFloat(item.discount || 0);
                const tax = parseFloat(item.tax_perc || 0);
                const gross = salePrice * quantity;
                const totalTaxable = gross - discount;
                const vat = totalTaxable * tax;

                const roundedVat = roundNumber(
                    vat,
                    roundingRule,
                    roundingPrecision + 1,
                );

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + roundedVat;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - roundedVat;
                }

                return total;
            }, 0);

            const totalDiscount = formattedDataSource.reduce((total, item) => {
                const discount = parseFloat(item.discount || 0);

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + discount;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - discount;
                }

                return total;
            }, 0);

            const totalNetAmount = formattedDataSource.reduce((total, item) => {
                const netAmount = parseFloat(item.net_amount || 0);

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + netAmount;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - netAmount;
                }

                return total;
            }, 0);

            const salesTypeStats = formattedDataSource.reduce((stats, item) => {
                const salesType = item.sales_type;
                const quantity = parseInt(item.quantity || 0);
                const salePrice = parseFloat(item.salePrice || 0);
                const gross = salePrice * quantity;
                const netAmount = parseFloat(item.net_amount || 0);
                stats[salesType] = stats[salesType] || {
                    count: 0,
                    gross: 0,
                    net: 0,
                };
                stats[salesType].count++;
                stats[salesType].gross += gross;
                stats[salesType].net += netAmount;
                return stats;
            }, {});

            const formattedDate = moment(formData.date).format('YYYY-MM-DD');
            const currentTime = moment().format('HH:mm:ss');
            const endTime = moment().unix(); // Use .unix() to get the timestamp
            const duration = endTime - startTime;
            const formattedStartTime = moment
                .unix(startTime)
                .format('YYYY-MM-DD HH:mm:ss');
            const formattedEndTime = moment
                .unix(endTime)
                .format('YYYY-MM-DD HH:mm:ss');

            // Convert times to UTC before sending them
            const requestData = {
                invoice_no: text.toString(),
                transaction_started_time: convertLocalToUTC(
                    formattedStartTime,
                    'YYYY-MM-DD HH:mm:ss',
                    'time',
                ),
                transaction_ended_time: convertLocalToUTC(
                    formattedEndTime,
                    'YYYY-MM-DD HH:mm:ss',
                    'time',
                ),
                sales_date: convertLocalToUTC(
                    formattedDate,
                    'YYYY-MM-DD',
                    'date',
                ),
                customer: customer.id,
                customer_code: customer.code,
                customer_name: customer.name,
                customer_street: customer.street,
                customer_building_number: customer.building_number,
                customer_city: customer.city,
                customer_postal_code: customer.postal_code,
                customer_district: customer.district,

                route: route,
                route_code: routeCode,
                route_name: routeName,
                ...(selectedSalesman && {
                    salesman: selectedSalesman.id,
                    salesman_name: selectedSalesman.name,
                    salesman_code: selectedSalesman.code,
                }),

                gross_amount: grossAmount,
                remarks: remarks,
                currency_id: selectedCurrency,
                currency: selectedCurrencyName,
                currency_code: selectedCurrencyCode,
                status: 'printed',
                total_taxable_amount: roundNumber(
                    totalTaxable,
                    roundingRule,
                    roundingPrecision + 1,
                ),
                net_amount: roundNumber(
                    totalNetAmount,
                    roundingPrecision,
                    roundingRule,
                ),
                total_discount: roundNumber(
                    totalDiscount,
                    roundingPrecision,
                    roundingRule,
                ),
                tax_amount: roundNumber(
                    totalTax,
                    roundingPrecision,
                    roundingRule,
                ),
                trans_type: transName,
                sales_time: convertLocalToUTC(currentTime, 'HH:mm:ss', 'time'),
                transaction_duration: parseFloat(duration),
                paid: 0,
                balance: totalNetAmount,
                sales_details: formattedDataSource.map(item => ({
                    detail_code: item.key.toString(),
                    sales_type_id: item.sales_type_id,
                    sales_type: item.sales_type,
                    product: item.product,
                    product_code: item.product_code,
                    product_name: item.product_name,
                    cost_price: item.cost_price,
                    price_id: item.price_id,
                    multipack_quantity: parseFloat(item?.quantity),
                    min_sales: item.min_sales,
                    max_discount: item.max_discount,
                    group_id: item.group_id,
                    group_name: item.group_name,
                    group_code: item.group_code,
                    tax_1: item.tax_1,
                    tax_2: item.tax_2,
                    tax_perc: item.tax_perc,
                    tax_amount: item.tax_total,
                    product_price: item.product_price,
                    discount_amount: item.discount ? item.discount : 0,
                    gross_amount: item.gross_amount,
                    multipack_id: item.multipack_id,
                    multipack_code: item.multipack_code,
                    multipack_name: item.multipack_name,
                    base_multipack_id: item.base_multipack_id,
                    base_multipack_code: item.base_multipack_code,
                    base_multipack_name: item.base_multipack_name,
                    multiplier: item.multiplier,
                    discount_perc: item.discount_perc,
                    sale_price: item.salePrice,
                    net_amount: item.net_amount,
                    total_taxable_amount: item.total_taxable_amount,
                    detail_reference: item?.detail_code,
                })),
                total_sales_count: salesTypeStats.sales?.count || 0,
                total_sales: salesTypeStats.sales?.gross || 0,
                total_sales_net: salesTypeStats.sales?.net || 0,
                total_foc_count: salesTypeStats.foc?.count || 0,
                total_foc: salesTypeStats.foc?.gross || 0,
                total_gr_count: salesTypeStats.gr?.count || 0,
                total_gr_net: salesTypeStats.gr?.net || 0,
                total_gr: salesTypeStats.gr?.gross || 0,
                total_expiry_count: salesTypeStats.expiry?.count || 0,
                total_expiry: salesTypeStats.expiry?.gross || 0,
                total_damage_count: salesTypeStats.damage?.count || 0,
                total_damage: salesTypeStats.damage?.gross || 0,
                billing_reference: billingReference ? billingReference : null,
            };

            if (grSelected) {
                if (!partialInvoice && grQuantityValidation) {
                    requestData.return_status = 'completely';
                } else if (partialInvoice && !grQuantityValidation) {
                    requestData.return_status = 'partially';
                }
            }

            // Check if the transaction type is "cash"
            if (transName === 'cash') {
                // If multi-sales type is allowed or sales type is "gr"
                if (multiSalesTypeAllowed || selectedSalesType?.name === 'gr') {
                    // Show collection modal if totalNetAmount is positive
                    if (totalNetAmount > 0) {
                        setCollectionModalVisible(true);
                        setTotalBalance(totalNetAmount);
                        setInitialTotalBalance(totalNetAmount);

                        // Store requestData for use in onFix
                        setRequestData(requestData);

                        // Set default values for the collection form
                        collectionForm.setFieldsValue({
                            collection: [
                                {
                                    paymentType: '',
                                    amount: totalNetAmount,
                                },
                            ],
                        });
                    } else {
                        // Do not show the collection modal if totalNetAmount is less than or equal to 0
                        collectionForm.resetFields();
                        confirmSales(requestData);
                    }
                } else {
                    // For sales types other than "gr" or if multiSalesTypeAllowed is not true
                    setCollectionModalVisible(true);
                    setTotalBalance(totalNetAmount);
                    setInitialTotalBalance(totalNetAmount);

                    // Store requestData for use in onFix
                    setRequestData(requestData);

                    // Set default values for the collection form
                    collectionForm.setFieldsValue({
                        collection: [
                            {
                                paymentType: '',
                                amount: totalNetAmount,
                            },
                        ],
                    });
                }
            } else {
                // For non-cash transactions, directly confirm sales
                confirmSales(requestData);
            }
        } catch (error) {
            // console.log(error);
            message.error({
                content: 'An error occurred while submitting data',
                key: 'form',
            });
        }
    };

    // confirm Sales API Function

    const confirmSales = async requestData => {
        const response = await postApiWithAuthToken(
            '/api/v1/admin/salesSummary/confirmSales',
            requestData,
        );

        if (!response.error) {
            message.success({content: response.data, key: 'form'});
            setConfirmFinal(true);
            const latest = {
                ...requestData,
                inv: response.data?.invoice_no,
            };
            setInvoiceData(latest);
        } else {
            message.error({
                content: 'Failed to submit data',
                key: 'form',
            });
        }
    };

    //customer Details
    //get customer list and route
    function getCustomers() {
        getApiWithAuthToken('/api/v1/admin/customers').then(res => {
            if (res.data?.customers?.length > 0) {
                setCustomerDet(res.data?.customers);
                setCustData(false);
            } else {
                setCustomerDet([]);
            }
        });
    }
    //getRoutes
    function getRoute() {
        getApiWithAuthToken('/api/v1/admin/routes/transactionAllowed').then(
            res => {
                if (res.data?.length > 0) {
                    setRouteDet(res.data);
                } else {
                    setRouteDet([]);
                }
            },
        );
    }

    const getSalesTypes = async () => {
        try {
            const response = await getApiWithAuthToken(
                '/api/v1/admin/salesTypes',
            );
            if (!response.error && response.data?.length > 0) {
                setSalesType(response.data);
            } else {
            }
        } catch (error) {}
    };

    useEffect(() => {
        getCustomers();
        getRoute();
        getCurrency();
        getCompanyDetails();
        getSalesTypes();
    }, []);

    //saleSummary confirm

    const onFinish = values => {
        const formattedDate = moment(values.date).format('YYYY-MM-DD');

        const submissionValues = {
            ...values,
            sales_date: formattedDate,
            invoice_no: text.toString(),

            currency_id: currencyId,
            currency: currency,
            vat_reg_no: vatRegNo,
            customer: customer.id,
            customer_code: customer.code,
            customer_name: customer.name,
            customer_street: customer.street,
            customer_building_number: customer.building_number,
            customer_city: customer.city,
            customer_postal_code: customer.postal_code,
            customer_district: customer.district,

            route: route,
            route_code: routeCode,
            route_name: routeName,
            ...(selectedSalesman && {
                salesman_id: selectedSalesman.id,
                salesman_name: selectedSalesman.name,
                salesman_code: selectedSalesman.code,
            }),
            currency_id: selectedCurrency,
            currency: selectedCurrencyName,
            currency_code: selectedCurrencyCode,
            trans_type: transName,
            remarks: remarks,
        };

        postApiWithAuthToken(
            `/api/v1/admin/salesSummary`,
            submissionValues,
        ).then(res => {
            if (!res.error) {
                message.success({content: res.data, key: 'form'});
                setConfirm(true);
            } else {
                if (res.error) {
                    message.error({content: res.error, key: 'form'});
                }
            }
        });
    };

    // Edit function

    //Add Sales items
    const EditableContext = React.createContext(null);
    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false} onFinish={FinalSubmit}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        handleRemoveRow,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const [inputValue, setInputValue] = useState(record?.[dataIndex] ?? ''); // State to store input value with default value if undefined
        const [minValue, setMinValue] = useState(record?.min_sales || 0);

        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing && inputRef.current) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            setEditing(!editing);
            setInputValue(record[dataIndex] ?? '');
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };
        const handleChange = (e, allowSpecialLogic = false) => {
            const {value} = e.target;
            if (allowSpecialLogic) {
                setInputValue(parseFloat(value));
            } else {
                setInputValue(Math.max(parseFloat(value), minValue));
            }
        };

        const handleBlur = () => {
            if (inputValue !== record[dataIndex]) {
                handleSave({
                    ...record,
                    [dataIndex]: inputValue,
                });
            }
            setEditing(false); // Always set editing to false on blur
        };
        const handleQuantityBlur = () => {
            if (inputValue !== record[dataIndex]) {
                if (routeEInvoiceEnable && grSelected) {
                    if (record.pending_quantity < inputValue) {
                        handleSave({
                            ...record,
                            quantity: record.pending_quantity,
                        });
                        return;
                    } else {
                        handleSave({
                            ...record,
                            quantity: inputValue,
                        });
                    }
                    setGrQuantityValidation(false);
                    setPartialInvoice(true);

                    if (record.pending_quantity === parseInt(inputValue)) {
                        setGrQuantityValidation(true);
                        setPartialInvoice(false);
                    }
                } else {
                    // Call handleQuantityChange to recalculate the price
                    handleQuantityChange(
                        record.key,
                        inputValue,
                        record.salesType,
                    );
                }
            }

            setEditing(false);
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                if (routeEInvoiceEnable && dataIndex === 'quantity') {
                    if (record.pending_quantity < values.quantity) {
                        handleSave({
                            ...record,
                            ...values,
                            quantity: record.pending_quantity,
                        });
                        return;
                    } else {
                        handleSave({
                            ...record,
                            ...values,
                        });
                    }
                    setGrQuantityValidation(false);
                    setPartialInvoice(true);

                    // Call handleQuantityChange to recalculate the price
                    handleQuantityChange(
                        record.key,
                        inputValue,
                        record.salesType,
                    );

                    if (record.pending_quantity === parseInt(values.quantity)) {
                        setGrQuantityValidation(true);
                        setPartialInvoice(false);
                    }
                } else if (dataIndex === 'quantity') {
                    handleSave({
                        ...record,
                        ...values,
                    });

                    // Call handleQuantityChange to recalculate the price
                    handleQuantityChange(
                        record.key,
                        inputValue,
                        record.salesType,
                    );
                } else {
                    handleSave({
                        ...record,
                        ...values,
                    });
                }
            } catch (errInfo) {}
        };

        let childNode = children;

        if (editable && dataIndex === 'quantity') {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                        maxWidth: 100,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Input
                        type='number'
                        style={{textAlign: 'right'}}
                        ref={inputRef}
                        onPressEnter={save}
                        max={
                            routeEInvoiceEnable ? record.pending_quantity : null
                        }
                        min={0}
                        onBlur={handleQuantityBlur}
                        onChange={e => handleChange(e, allowDecimal)}
                    />
                </Form.Item>
            ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{
                        paddingRight: 24,
                        height: '30px',
                        padding: '4px 11px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        textAlign: 'right',
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        } else if (editable && dataIndex === 'salePrice') {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                        maxWidth: 200,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const minValue = record['min_sales']; // Access minSalesPrice from the record object

                                if (
                                    !isNaN(minValue) &&
                                    !isNaN(value) &&
                                    value >= minValue
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        `The ${title} must be at least ${minValue}.`,
                                    ),
                                );
                            },
                        }),
                    ]}
                    initialValue={record[dataIndex]} // Set initial value
                >
                    <Input
                        type='number'
                        ref={inputRef}
                        onPressEnter={toggleEdit}
                        onBlur={handleBlur}
                        value={inputValue}
                        onChange={handleChange}
                        style={{textAlign: 'right'}}
                    />
                </Form.Item>
            ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{
                        paddingRight: 24,
                        height: '30px',
                        padding: '4px 11px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        textAlign: 'right',
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        } else if (editable && dataIndex === 'action') {
            childNode = (
                <Button type='link' danger>
                    Remove
                </Button>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    //function to handle selection of SalesType
    const handleSalesTypeChange = (recordKey, value, index) => {
        const selectedSalesType = salesType.find(type => type.name === value);

        // Lock the sales type if it's not already locked
        if (routeEInvoiceEnable) {
            setLockedSalesType(value);
            if (value === 'gr') {
                setGrSelected(true);
                setSelectedSalesType(selectedSalesType);
                showEInvoiceModal();
            } else {
                setSelectedSalesType(selectedSalesType);
            }
        } else if (!multiSalesTypeAllowed) {
            setLockedSalesType(value);
        }

        // Ensure all rows have the correct sales type and ID
        const updatedDataSource = dataSource.map(item => {
            if (item.key === recordKey || !item.salesType) {
                return {
                    ...item,
                    salesType: lockedSalesType || value,
                    sales_type_id: selectedSalesType
                        ? selectedSalesType.id
                        : null,
                    sales_type: selectedSalesType
                        ? selectedSalesType.name
                        : null,
                };
            }
            return item;
        });

        setDataSource(updatedDataSource);
    };

    // JSX for rendering select input in table
    const renderSalesTypeSelect = (record, index) => {
        const isLocked = !!lockedSalesType;
        const tooltipMessage =
            'Sales type already selected and multisales type is not allowed';

        return (
            <Tooltip title={isLocked ? tooltipMessage : null}>
                <Select
                    value={lockedSalesType || record.salesType}
                    onChange={value =>
                        handleSalesTypeChange(record.key, value, index)
                    }
                    style={{width: '100px'}}
                    allowClear={!isLocked} // Disable clear icon when locked
                    disabled={isLocked} // Disable selection if sales type is locked
                    placeholder={
                        isLocked ? tooltipMessage : 'Select Sales Type'
                    }
                >
                    {salesType.map(type => (
                        <Select.Option key={type.id} value={type.name}>
                            {type.name}
                        </Select.Option>
                    ))}
                </Select>
            </Tooltip>
        );
    };
    //Edit table components

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    //state for discount editable cells

    const [editingDiscount, setEditingDiscount] = useState(true);
    const [totalDiscounValue, setTotalDiscountValue] = useState(0);
    //productlist modal state
    const [productModal, setProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    //multipack list state
    const [selectedMultipackValues, setSelectedMultipackValues] = useState({});
    const [grMultipackValues, setGrMultipackValues] = useState({});
    const [priceDataResponse, setPriceDataResponse] = useState([]);
    const [allowDecimal, setAllowDecimal] = useState(false);

    const [currentlyEditingKey, setCurrentlyEditingKey] = useState(null);
    const inputRef = useRef(null); // Define a ref for the input field

    // Focus on the input field when the modal is opened
    useEffect(() => {
        if (productModal) {
            inputRef.current.focus(); // Focus on the input field
        }
    }, [productModal]);

    //productlist modal view

    const handleProductModal = (record, rowIndex) => {
        setSelectedProduct({...record, rowIndex});
        setProductModal(true);
    };

    const handleEditDiscount = async (key, value, max_discount) => {
        // Convert value to a number
        value = parseFloat(value);

        // Check if the entered value is a valid number
        if (isNaN(value)) {
            // If the entered value is not a number, set it to 0
            value = 0;
        }
        const isExceedingMax = max_discount && value > max_discount;
        const tooltipValue = isExceedingMax ? max_discount : value;

        // // Check if the entered value exceeds the maxDiscount
        // if (max_discount && value > max_discount) {

        //     value = max_discount;
        // }

        // Create a copy of the dataSource to avoid mutating the original data
        const newData = dataSource.map(item => {
            if (item.key === key) {
                return {...item, discount: tooltipValue};
            }
            return item;
        });

        // Calculate total discount value
        const totalDiscountValue = newData.reduce(
            (total, item) => total + item.discount,
            0,
        );

        setTotalDiscountValue(totalDiscountValue);
        setCurrentlyEditingKey(key);

        // Reset total discount value if any discount exceeds maxDiscount
        const exceedsMaxDiscount = newData.some(
            item => item.discount > (item.max_discount || Infinity),
        );

        if (exceedsMaxDiscount) {
            setTotalDiscountValue(0);
            message.error('Discount exceeds maximum allowed value');
        } else {
            // Update the dataSource state only if no discount exceeds maxDiscount
            setDataSource(newData);
        }

        try {
            // Construct the payload with only the necessary fields and remove unwanted fields
            const payload = newData.map(item => {
                const {
                    multipack,
                    salesType,
                    salePrice,
                    quantity,
                    transactionType,
                    total,
                    vat,

                    ...payloadItem
                } = item;
                return {
                    ...payloadItem,
                    sales_code: text.toString(),
                    detail_code: item.key.toString(),
                    product: item.product.id,
                    product_name: item.product.name,
                    product_code: item.product.code,
                    multipack_quantity: parseInt(item.quantity),
                    gross_amount: item.salePrice * item.quantity,
                    total_taxable_amount: roundNumber(
                        item.salePrice * item.quantity - item.discount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    tax_amount: roundNumber(
                        (item.salePrice * item.quantity - item.discount) *
                            item.tax_perc,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    multipack_id: item.multipack[0].id,
                    multipack_code: item.multipack[0].code,
                    multipack_name: item.multipack[0].name,
                    base_multipack_id: item.multipack[0].base_multipack_id,
                    base_multipack_code: item.multipack[0].base_multipack_code,
                    base_multipack_name: item.multipack[0].base_multipack_name,
                    multiplier: item.multipack[0].multiplier,
                    discount_perc: item.discountPercentage,
                    discount_amount: item.discount ? item.discount : 0,
                    sale_price: item.salePrice,
                };
            });

            const response = await postApiWithAuthToken(
                '/api/v1/admin/salesDetails/draft',
                {
                    sales_details: payload,
                },
            );

            if (!response.error) {
            }
            // Handle response if needed
        } catch (error) {
            // Handle error if needed
        }
    };

    const handleProductModalclose = () => {
        setProductModal(false);
        setSearchInput('');
        setFilterOption('name');
    };
    const handleProductSelect = async productId => {
        const selected = productList.find(product => product.id === productId);
        setSelectProductId(selected.id);
        const tax1 = typeof selected.tax_1 === 'number' ? selected.tax_1 : 0;
        const tax2 = typeof selected.tax_2 === 'number' ? selected.tax_2 : 0;
        const totalTax = tax1 + tax2;

        // Make API call to fetch multipack options
        try {
            const response = await getApiWithAuthToken(
                `/api/v1/admin/productMultipacks/price?product_id=${productId}&priceExist=1`,
            );
            if (response.data) {
                const multipackOptions = response.data.map(multipack => ({
                    id: multipack.id,
                    code: multipack.code,
                    name: multipack.name,
                    base_multipack_id: multipack.base_multipack_id,
                    base_multipack_code: multipack.base_multipack_code,
                    base_multipack_name: multipack.base_multipack_name,
                    multiplier: multipack.multiplier,
                }));

                const updatedDataSource = dataSource.map(item => {
                    if (item.key === selectedProduct.rowIndex) {
                        return {
                            ...item,
                            product: selected,
                            discount: 0,
                            tax_1: tax1,
                            tax_2: tax2,
                            tax_perc: totalTax,
                            multipack: multipackOptions, // Save multipack options in the item
                        };
                    }
                    return item;
                });

                setDataSource(updatedDataSource);
                setSearchInput('');
            }
        } catch (error) {
            message.error('Failed to fetch multipack options');
        }

        setSelectedProduct(null);
        setProductModal(false);
        setFilterOption('name');
    };

    // Inside setEditingDiscount function
    const toggleEditingDiscount = value => {
        // Reset total discount value and discount fields when turning off editing mode
        if (!value) {
            const resetDiscountData = dataSource.map(item => {
                return {...item, discount: 0};
            });
            setDataSource(resetDiscountData);
            setTotalDiscountValue(0);
        }
        setEditingDiscount(value);
    };

    const customerField = (
        <Form.Item name='customer' label='Customer'>
            <Select
                style={{width: '200px'}}
                loading={custData}
                showSearch
                disabled={confirm}
                placeholder='Select Customer'
                optionFilterProp='children'
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                onSelect={customerId => {
                    const newStartTime = moment().format('X');
                    setStartTime(newStartTime);
                    const selectedCustomer = customerDet.find(
                        customer => customer.id === customerId,
                    );
                    if (selectedCustomer) {
                        setCustomer(selectedCustomer);
                    }
                }}
            >
                {customerDet.map(customer => (
                    <Select.Option key={customer.id} value={customer.id}>
                        {customer.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
    // Function to handle route selection
    const handleRouteSelect = routeId => {
        if (routeDet) {
            const selectedRoute = routeDet.find(route => route.id === routeId);
            if (selectedRoute && selectedRoute.available === true) {
                setRoute(routeId);
                setRouteName(selectedRoute.name);
                setRouteCode(selectedRoute.code);
                setRouteEInvoiceEnable(selectedRoute?.e_invoice_enabled);
                setSalesman([
                    {
                        id: selectedRoute.salesman_id,
                        name: selectedRoute.salesman,
                        code: selectedRoute.salesman_code,
                    },
                ]);
                form.setFieldsValue({
                    salesman_id: null, // Reset salesman select box
                });
            } else {
                // If the selected route is not active, you may want to reset the form or show a message
                message.error('The selected route is not active.');
                form.resetFields(['route_id']);
            }
        }
    };

    const transTypeField = (
        <Form.Item name='trans_type' label='Trans Type'>
            <Select
                style={{width: '200px'}}
                disabled={confirm || !customer}
                placeholder='Select Transaction Type'
                optionFilterProp='children'
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >
                    0
                }
                value={transName}
                onSelect={trans => {
                    setTransName(trans);
                }}
            >
                {transType?.split(',').map((type, index) => (
                    <Select.Option key={index} value={type.trim()}>
                        {type.trim()}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
    //check for priceType and price

    const handleMultipackChange = async (recordKey, value, salesType) => {
        setSelectedMultipackValues(prevState => ({
            ...prevState,
            [recordKey]: value,
        }));

        try {
            const res = await getApiWithAuthToken(
                `/api/v1/admin/priceKeyGroups/price/${productId}/${customer.id}/${route}/${value}`,
            );

            if (!res.error && res.data && res.data.length > 0) {
                setPriceDataResponse(res.data);
            } else {
            }
        } catch (error) {
            // Handle API call error
        }
    };

    const handleQuantityChange = (recordKey, quantity, salesType) => {
        if (routeEInvoiceEnable && grSelected) {
            // console.log('working');
        } else {
            const matchingItems = priceDataResponse.filter(item => {
                return (
                    quantity >= item.from_quantity &&
                    quantity <= item.to_quantity
                );
            });

            if (matchingItems.length > 0) {
                let selectedPriceData;

                if (matchingItems.length === 1) {
                    selectedPriceData = matchingItems[0];
                } else {
                    // If multiple matching items, sort them by priority
                    const sortedItems = matchingItems.sort(
                        (a, b) => a.priority - b.priority,
                    );
                    selectedPriceData = sortedItems[0];
                }

                // Set allowDecimal based on the selectedPriceData
                const allowDecimalValue =
                    selectedPriceData?.allow_decimal_quantity ?? false;
                setAllowDecimal(allowDecimalValue);

                // Set the selected price based on salesType
                let selectedPrice = 0;
                switch (salesType) {
                    case 'sales':
                        selectedPrice = selectedPriceData.sales;
                        break;
                    case 'foc':
                        selectedPrice = selectedPriceData.foc;
                        break;
                    case 'gr':
                        selectedPrice = selectedPriceData.gr;
                        break;
                    case 'expiry':
                        selectedPrice = selectedPriceData.expiry;
                        break;
                    case 'damage':
                        selectedPrice = selectedPriceData.damage;
                        break;
                    default:
                        selectedPrice = 0;
                }

                // Update the dataSource with the selected price
                const updatedDataSource = dataSource.map(item => {
                    if (item.key === recordKey) {
                        return {
                            ...item,
                            salePrice: selectedPrice,
                            product_price: selectedPrice,
                            min_sales: selectedPriceData.min_sales,
                            max_discount:
                                selectedPriceData.max_discount || null,
                            salesType: salesType,
                            cost_price: selectedPriceData.cost_price,
                            price_id: selectedPriceData.id,
                            group_code: selectedPriceData.group_code,
                            group_id: selectedPriceData.group_id,
                            group_name: selectedPriceData.name,

                            quantity: quantity,
                        };
                    }
                    return item;
                });
                setDataSource(updatedDataSource);
            }
        }
    };

    //remove function of each row
    const handleRemoveRow = async key => {
        try {
            // Filter out the removed row from dataSource
            const updatedDataSource = dataSource.filter(
                item => item.key !== key,
            );
            setDataSource(updatedDataSource);

            // Reset lockedSalesType if there are no rows left in the data source
            if (updatedDataSource.length === 0) {
                setTotalDiscountValue(0);
                setLockedSalesType(null);
            }

            // Remove the corresponding entry from selectedMultipackValues
            setSelectedMultipackValues(prevState => {
                const newState = {...prevState};
                delete newState[key];
                return newState;
            });

            // setpartialInvoice(true);
            if (routeEInvoiceEnable && grSelected) {
                setPartialInvoice(true);
                setGrQuantityValidation(false);
            }

            // Wrap the payload in the desired format
            const payload = {
                sales_details: updatedDataSource.map(item => ({
                    detail_code: item.key.toString(),
                    sales_code: text.toString(),
                    product: item.product.id,
                    product_name: item.product.name,
                    product_code: item.product.code,
                    gross_amount: item.salePrice * item.quantity,
                    total_taxable_amount: roundNumber(
                        item.salePrice * item.quantity - item.discount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    tax_amount: roundNumber(
                        (item.salePrice * item.quantity - item.discount) *
                            item.tax_perc,
                        roundingRule,
                        roundingPrecision + 1,
                    ),

                    tax_1: item.tax_1,
                    tax_2: item.tax_2,
                    multipack_id: item.multipack.id,
                    multipack_code: item.multipack.code,
                    multipack_name: item.multipack.name,
                    base_multipack_id: item.multipack.base_multipack_id,
                    base_multipack_code: item.multipack.base_multipack_code,
                    base_multipack_name: item.multipack.base_multipack_name,
                    multiplier: item.multipack.multiplier,
                    discount_perc: item.discount_perc,
                    discount_amount: item.discount ? item.discount : 0,
                })),
            };

            // Remove multipack object from the payload
            // payload.sales_details.forEach(item => delete item.multipack);
            payload.sales_details.forEach(item => delete item.salesType);
            payload.sales_details.forEach(item => delete item.tax_perc);
            payload.sales_details.forEach(item => delete item.transactionType);

            // Make the API call
            const response = await postApiWithAuthToken(
                '/api/v1/admin/salesDetails/draft',
                payload,
            );

            if (response && response.data) {
                message.success(response.data);
            } else {
            }
        } catch (error) {}
    };

    //rounding numbers

    function roundNumber(value, roundingRule, roundingPrecision) {
        if (roundingRule === 'round') {
            return (
                Math.round(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else if (roundingRule === 'ceil') {
            return (
                Math.ceil(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else if (roundingRule === 'floor') {
            return (
                Math.floor(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else {
            // Default to rounding to 2
            return Math.round(value * 100) / 100;
        }
    }

    //add form table columns

    const formColumns = [
        {
            title: 'Sales Type',
            dataIndex: 'sales_type',
            render: (text, record, index) =>
                renderSalesTypeSelect(record, index),
        },
        {
            title: 'Product',
            dataIndex: 'product',
            width: '15%',
            editable: true,
            render: (_, record) => {
                return (
                    <span>
                        {grSelected ? (
                            record.product ? (
                                record.product.name
                            ) : (
                                ''
                            )
                        ) : (
                            <span
                                onClick={() =>
                                    handleProductModal(record, record.key)
                                }
                            >
                                {record.product ? (
                                    record.product.name
                                ) : (
                                    <Button>Select Product</Button>
                                )}
                            </span>
                        )}
                    </span>
                );
            },
        },

        {
            title: 'Multipack',
            dataIndex: 'multipack',
            editable: true,
            width: '10%',
            render: (_, record) => {
                const rowIndex = dataSource.findIndex(
                    item => item.key === record.key,
                );
                // console.log('grMultipackValues', grMultipackValues);
                return grMultipackValues[0] ? (
                    <Input
                        readOnly
                        value={grMultipackValues[0]?.name}
                        style={{width: '100%'}}
                    />
                ) : (
                    <Select
                        disabled={!record.product}
                        value={selectedMultipackValues[rowIndex]} // Set the selected value based on the row key
                        onChange={value =>
                            handleMultipackChange(
                                record.key,
                                value,
                                record.salesType,
                            )
                        }
                        style={{width: '100%'}}
                    >
                        {multipackList.map(multipack => (
                            <Select.Option
                                key={multipack.id}
                                value={multipack.id}
                            >
                                {multipackNames[multipack.id] || multipack.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '10%',
            editable: true,
        },
        {
            title: 'Price',
            dataIndex: 'salePrice',
            editable: !grSelected,
            width: '10%',
        },
        {
            title: 'Gross Amount',
            dataIndex: 'gross_amount',
            width: '13%',
            render: (_, record) => {
                const {salePrice, quantity} = record;
                //net Amount Calculation
                const Gross = salePrice * quantity;

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {roundNumber(Gross, roundingRule, roundingPrecision)}
                    </span>
                );
            },
        },
        {
            title: !grSelected ? (
                <span>
                    Discount
                    <Button
                        type='link'
                        size='small'
                        onClick={() => toggleEditingDiscount(!editingDiscount)}
                    >
                        {editingDiscount ? 'Reset' : 'Edit'}
                    </Button>
                </span>
            ) : (
                'Discount'
            ),
            dataIndex: 'discount',
            editable: editingDiscount,
            width: '10%',
            render: (_, record) => {
                const {key, discount, max_discount, product} = record;
                const totalItems = dataSource.length;
                let updatedDiscount = discount; // Start with the saved discount value

                // Only calculate divided discount if a product is selected
                if (product && totalDiscounValue) {
                    // Divide total discount by the number of items
                    let dividedDiscount = totalDiscounValue / totalItems;

                    // Ensure dividedDiscount doesn't exceed max_discount
                    if (max_discount && dividedDiscount > max_discount) {
                        dividedDiscount = max_discount;
                    }

                    // Use divided discount if editing is not enabled
                    updatedDiscount = editingDiscount
                        ? discount
                        : dividedDiscount;
                }

                // Format the discount for display
                const formattedDiscount = roundNumber(
                    updatedDiscount,
                    roundingPrecision,
                    roundingRule,
                );

                const inputComponent = (
                    <Input
                        value={formattedDiscount.toString()} // Display the correct discount
                        onChange={e =>
                            handleEditDiscount(
                                key,
                                e.target.value,
                                max_discount,
                            )
                        }
                        ref={inputRef => {
                            if (inputRef && key === currentlyEditingKey) {
                                inputRef.focus();
                            }
                        }}
                        onBlur={() => setCurrentlyEditingKey(null)}
                        style={{textAlign: 'right'}}
                        disabled={!product || grSelected} // Disable if no product is selected
                    />
                );

                return editingDiscount ? (
                    <Tooltip
                        title={
                            max_discount
                                ? `Max discount is: ${max_discount}`
                                : null
                        }
                        trigger={['focus', 'hover']}
                        placement='topRight'
                    >
                        {inputComponent}
                    </Tooltip>
                ) : (
                    <span onClick={() => setCurrentlyEditingKey(key)}>
                        {formattedDiscount}
                    </span>
                );
            },
        },

        {
            title: 'Tot Tax Amt.',
            dataIndex: 'total_taxable_amt',
            width: '13%',
            render: (_, record) => {
                const {salePrice, quantity, discount} = record;
                // vat calculation here
                const total_taxable_amt = roundNumber(
                    salePrice * quantity - discount,
                    roundingRule,
                    roundingPrecision,
                );
                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {total_taxable_amt}
                    </span>
                );
            },
        },

        {
            title: 'Tax Amt.',
            dataIndex: 'vat',
            width: '10%',
            render: (_, record) => {
                const {
                    salePrice = 0,
                    quantity = 0,
                    tax_perc = 0,
                    discount = 0,
                } = record;
                const totalTaxable = salePrice * quantity - discount;
                // vat calculation here
                const vat = roundNumber(
                    totalTaxable * tax_perc,
                    roundingRule,
                    roundingPrecision,
                );

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {vat}
                    </span>
                );
            },
        },

        {
            title: 'Net Amount',
            dataIndex: 'total',
            width: '10%',
            render: (_, record) => {
                // Destructure with default values
                const {
                    tax_perc = 0,
                    salePrice = 0,
                    discount = 0,
                    quantity = 0,
                } = record;

                // Net Amount Calculation
                const gross = salePrice * quantity;
                const totalTaxable = gross - discount;
                const taxAmount = totalTaxable * tax_perc;
                const netAmount = totalTaxable + taxAmount;

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {roundNumber(
                            netAmount,
                            roundingRule,
                            roundingPrecision,
                        )}
                    </span>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'action',
            width: '5%',
            render: (_, record) => (
                <Button
                    type='link'
                    danger
                    onClick={() => handleRemoveRow(record.key)}
                >
                    Remove
                </Button>
            ),
        },
    ];

    const [dataSource, setDataSource] = useState([]);

    const [productList, setProductList] = useState([]);
    const [multipackList, setMultipackList] = useState([]);
    const [count, setCount] = useState(0);
    const [showTable, setShowTable] = useState(false);

    //product modal filter states
    const [searchInput, setSearchInput] = useState('');
    const [filterOption, setFilterOption] = useState('name');

    //multipack

    const [multipackNames, setMultipackNames] = useState({});

    //collection modal

    const [collectionForm] = Form.useForm();

    // States
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [paymentTypesCount, setPaymentTypesCount] = useState(0);
    const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
    const [transactionStartedTime, setTransactionStartedTime] = useState(null);
    const [totalBalance, setTotalBalance] = useState(0);
    const [initialTotalBalance, setInitialTotalBalance] = useState(0);
    const [totalAmounts, setTotalAmounts] = useState([]);

    useEffect(() => {
        if (collectionModalVisible) {
            setSelectedPaymentTypes([]);
            setTotalAmounts([]);
            setTotalBalance(initialTotalBalance);

            collectionForm.setFieldsValue({
                collection: [
                    {
                        paymentType: undefined,
                        amount: undefined,
                    },
                ],
            });
        }
    }, [collectionModalVisible, initialTotalBalance]);
    //fetch Products and multipacks
    useEffect(() => {
        fetchProductList();
        getPaymentTypes();
    }, []);
    useEffect(() => {
        fetchMultipackList();
    }, [productId]);

    function getPaymentTypes() {
        getApiWithAuthToken('/api/v1/admin/paymentTypes').then(res => {
            if (res.data?.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].active = true;
                }
                setPaymentTypesCount(res.data.length);
                setPaymentTypes(res.data);
            } else {
                setPaymentTypes([]);
            }
        });
    }

    const fetchProductList = async () => {
        try {
            const response = await getApiWithAuthToken(
                '/api/v1/admin/products/active?priceExist=1',
            );
            if (response.data) {
                setProductList(response.data);
            }
        } catch (error) {}
    };
    const fetchMultipackList = async () => {
        try {
            const response = await getApiWithAuthToken(
                `/api/v1/admin/productMultipacks/price?product_id=${productId}&priceExist=1`,
            );
            if (response.data) {
                const newMultipackNames = {};
                response.data.forEach(multipack => {
                    newMultipackNames[multipack.id] = multipack.name;
                });
                setMultipackNames(newMultipackNames);

                setMultipackList(response.data);
            }
        } catch (error) {}
    };

    const handleAdd = () => {
        // Reset lockedSalesType if there is no product selected
        if (dataSource.length === 0) {
            setLockedSalesType(null);
        }
        const selectedSalesType = salesType.find(
            type => type.name === lockedSalesType,
        );

        const newData = {
            key: dataSource.length.toString(),
            salesType: lockedSalesType || '',
            sales_type_id: selectedSalesType ? selectedSalesType.id : null,
            sales_type: selectedSalesType ? selectedSalesType.name : null,
            product: '',
            multipack: '',
            quantity: '',
            salePrice: '',
            vat: '',
            discount: '',
            total: '',
        };

        setDataSource([...dataSource, newData]);
        setCount(count + 1);
        setShowTable(true);
        setEditable(true);
    };

    const resetTransaction = () => {
        setLockedSalesType(null);
        setDataSource([]);
        setShowTable(false);
        setEditable(false);
        setCount(0);
    };

    const resetGr = () => {
        setDataSource([...grSalesDetails]);
        setPartialInvoice(false);
        setGrQuantityValidation(true);
    };

    const handleSave = async updatedRow => {
        try {
            const newData = [...dataSource];
            const index = newData.findIndex(
                item => updatedRow.key === item.key,
            );
            const oldRow = newData[index];

            // Calculate vat and total based on updatedRow values

            const grossAmount = roundNumber(
                updatedRow.salePrice * updatedRow.quantity,
            );
            const total = roundNumber(
                grossAmount - updatedRow.discount,
                roundingPrecision,
                roundingRule,
            );
            const vat = roundNumber(
                total * updatedRow.tax_perc,
                roundingRule,
                roundingPrecision,
            );
            const Net = roundNumber(
                total + vat,
                roundingRule,
                roundingPrecision + 1,
            );

            // Calculate discount percentage
            const discountPercentage = roundNumber(
                updatedRow.discount / grossAmount,
                roundingPrecision,
                roundingRule,
            );

            // Include the quantity, salePrice, and gross amount fields in the updatedRow
            const updatedRowWithValues = {
                ...oldRow,
                ...updatedRow,
                vat_amt: vat,
                net_amount: Net,
                total_taxable_amount: total,
                gross_amount: grossAmount,

                discount_perc: discountPercentage,
            };

            newData.splice(index, 1, updatedRowWithValues);
            setDataSource(newData);

            // Wrap the payload in the desired format
            const payload = {
                sales_details: dataSource.map(item => {
                    const salesDetail = {
                        ...item,
                        sales_code: text.toString(),
                        detail_code: item.key.toString(),
                        product: item.product.id,
                        product_name: item.product.name,
                        product_code: item.product.code,
                        gross_amount: item.salePrice * item.quantity,
                        tax_amount: roundNumber(
                            item.salePrice * item.quantity * item.totalTax,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        tax_1: item.tax1,
                        tax_2: item.tax2,
                        multipack_id: item.multipack.id,
                        multipack_code: item.multipack[0].code,
                        multipack_name: item.multipack[0].name,
                        base_multipack_id: item.multipack[0].base_multipack_id,
                        base_multipack_code:
                            item.multipack[0].base_multipack_code,
                        base_multipack_name:
                            item.multipack[0].base_multipack_name,
                        multiplier: item.multipack[0].multiplier,
                        discount_perc: item.discount_perc,
                        discount_amount: item.discount ? item.discount : 0,
                    };

                    // Include multipack_quantity if present
                    if (item.quantity) {
                        salesDetail.multipack_quantity = parseFloat(
                            item.quantity,
                        );
                    }

                    // Include sale_price if present
                    if (item.salePrice) {
                        salesDetail.sale_price = parseInt(item.salePrice);
                    }

                    // Include discount_amount if present
                    if (item.discount) {
                        salesDetail.discount_amount = parseInt(item.discount);
                    }

                    return salesDetail;
                }),
            };

            // Remove multipack object from the payload
            payload.sales_details.forEach(item => delete item.multipack);
            payload.sales_details.forEach(item => delete item.salesType);
            payload.sales_details.forEach(item => delete item.transactionType);
            payload.sales_details.forEach(item => delete item.quantity);
            payload.sales_details.forEach(item => delete item.salePrice);
            payload.sales_details.forEach(item => delete item.discount);
            payload.sales_details.forEach(item => delete item.vat);
            payload.sales_details.forEach(item => delete item.tax_perc);
            payload.sales_details.forEach(item => delete item.total);
            payload.sales_details.forEach(item => delete item.key);

            // Make the API call
            const response = await postApiWithAuthToken(
                '/api/v1/admin/salesDetails/draft',
                payload,
            );

            if (response && response.data) {
                message.success(response.data);
            } else {
            }
        } catch (error) {}
    };

    const columnss = formColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave, // Pass the handleSave function to each editable column
            }),
        };
    });

    //collection add modal

    const handleSelectChange = (value, index) => {
        // Set transaction time if not already set
        if (!transactionStartedTime) {
            const now = new Date();
            const timeString = now.toLocaleTimeString('en-US', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            setTransactionStartedTime(timeString);
        }

        // Update selected payment types
        const newSelectedTypes = [...selectedPaymentTypes];
        newSelectedTypes[index] = value;
        setSelectedPaymentTypes(newSelectedTypes);
    };

    // Handle amount change in form fields
    const handleAmountChange = (value, index) => {
        const numValue = parseFloat(value) || 0;
        const newAmounts = [...totalAmounts];
        newAmounts[index] = numValue;

        // Calculate total excluding current field
        const otherAmountsTotal = newAmounts.reduce(
            (sum, amount, idx) =>
                idx !== index ? sum + (parseFloat(amount) || 0) : sum,
            0,
        );

        // Update balance
        const newBalance = initialTotalBalance - (otherAmountsTotal + numValue);
        setTotalBalance(Math.max(0, newBalance));
        setTotalAmounts(newAmounts);
    };

    // Handle removing payment rows
    const handleRemoveRows = (index, remove) => {
        // Update amounts
        const newAmounts = [...totalAmounts];
        newAmounts.splice(index, 1);
        setTotalAmounts(newAmounts);

        // Recalculate total
        const newTotal = newAmounts.reduce(
            (sum, amount) => sum + (parseFloat(amount) || 0),
            0,
        );
        const newBalance = initialTotalBalance - newTotal;
        setTotalBalance(Math.max(0, newBalance));

        // Update selected payment types
        setSelectedPaymentTypes(prev => prev.filter((_, i) => i !== index));

        // Remove row
        remove(index);
    };
    // collection modal submit
    const onFix = async values => {
        const {collection} = values;

        const formattedData = collection.map(item => ({
            payment_type_id: item.paymentType,
            payment_type_name: paymentTypes.find(
                type => type.id === item.paymentType,
            )?.name,
            paid: parseFloat(item.amount),
        }));

        const data = {
            ...requestData, // Include original request data from state
            payment_types: formattedData,

            balance: 0, // Set balance to 0 for cash transactions
            paid: requestData.net_amount, // Set paid to net_amount for cash transactions
        };

        try {
            const res = await postApiWithAuthToken(
                '/api/v1/admin/salesSummary/confirmSales',
                data,
            );

            if (res.status === 'ok') {
                message.success({content: res.data});
                setConfirmFinal(true);
                setCollectionFinal(true);
                const latest = {
                    ...data,
                    collection_code: res?.data?.collection_code,
                    inv: res.data?.invoice_no,
                };
                setInvoiceData(latest);
                setCollectionData(latest);
                // history.push('/sales-summary');
            }
        } catch (error) {}
    };

    //final Value calculation for table summary
    const summaryRow = () => {
        const totalSales = dataSource.reduce((total, item) => {
            const salePrice = parseFloat(item.salePrice || 0);
            const quantity = parseInt(item.quantity || 0);
            const gross = salePrice * quantity;
            const roundedGross = roundNumber(
                gross,
                roundingRule,
                roundingPrecision + 1,
            );

            if (item.salesType === 'sales' || item.salesType === 'foc') {
                return total + roundedGross;
            } else if (
                item.salesType === 'gr' ||
                item.salesType === 'damage' ||
                item.salesType === 'expiry'
            ) {
                return total - roundedGross;
            }

            // return total;
        }, 0);

        const totalVat = dataSource.reduce((total, item) => {
            const salePrice = parseFloat(item.salePrice || 0);
            const quantity = parseInt(item.quantity || 0);
            const discount = parseFloat(item.discount || 0);
            const vat = salePrice * quantity - discount;
            const roundedVat = roundNumber(
                vat,
                roundingRule,
                roundingPrecision + 1,
            );

            if (item.salesType === 'sales' || item.salesType === 'foc') {
                return total + roundedVat;
            } else if (
                item.salesType === 'gr' ||
                item.salesType === 'damage' ||
                item.salesType === 'expiry'
            ) {
                return total - roundedVat;
            }

            // return total;
        }, 0);

        const totalTax = dataSource.reduce((total, item) => {
            const salePrice = parseFloat(item.salePrice || 0);
            const quantity = parseInt(item.quantity || 0);
            const discount = parseFloat(item.discount || 0);
            const tax = parseFloat(item.tax_perc || 0);

            const gross = salePrice * quantity;
            const totalTaxable = gross - discount;
            const vat = totalTaxable * tax;
            const roundedVat = roundNumber(
                vat,
                roundingRule,
                roundingPrecision + 1,
            );

            if (item.salesType === 'sales' || item.salesType === 'foc') {
                return total + roundedVat;
            } else if (
                item.salesType === 'gr' ||
                item.salesType === 'damage' ||
                item.salesType === 'expiry'
            ) {
                return total - roundedVat;
            }

            // return total;
        }, 0);

        const totalNetAmount = dataSource.reduce((total, item) => {
            const salePrice = parseFloat(item.salePrice || 0);
            const quantity = parseInt(item.quantity || 0);
            const vat = parseFloat(item.tax_perc || 0);
            const discount = parseFloat(item.discount || 0);
            const gross = salePrice * quantity;
            const taxable = gross - discount;
            const vatAmount = taxable * vat;
            const netAmount = taxable + vatAmount;
            const roundedNetAmount = roundNumber(
                netAmount,
                roundingRule,
                roundingPrecision + 1,
            );

            if (item.salesType === 'sales' || item.salesType === 'foc') {
                return total + roundedNetAmount;
            } else if (
                item.salesType === 'gr' ||
                item.salesType === 'damage' ||
                item.salesType === 'expiry'
            ) {
                return total - roundedNetAmount;
            }

            // return total;
        }, 0);

        const handleTotalDiscountChange = async value => {
            // Convert value to a number
            value = parseFloat(value);

            // Ensure the total discount value is valid
            if (isNaN(value) || value < 0) {
                value = 0;
            }
            // Calculate the maximum possible total discount based on max_discount for all items
            const maxPossibleTotalDiscount = dataSource.reduce(
                (total, item) => {
                    return total + (item.max_discount ? item.max_discount : 0);
                },
                0,
            );

            // If the entered discount exceeds the max possible discount, set it to max possible discount
            if (value > maxPossibleTotalDiscount) {
                value = maxPossibleTotalDiscount;
            }

            // Calculate the total quantity of all products
            const totalQuantity = dataSource.length;

            // Calculate the individual discount per item
            const individualDiscount =
                totalQuantity > 0 ? value / totalQuantity : 0;

            // Update the discount value for each row in the dataSource with rounding
            const newData = dataSource.map(item => {
                const {max_discount} = item;

                let calculatedDiscount = roundNumber(
                    individualDiscount,
                    roundingPrecision,
                    roundingRule,
                );

                if (
                    max_discount !== null &&
                    calculatedDiscount > max_discount
                ) {
                    calculatedDiscount = max_discount;
                }
                // Calculate vat and total based on updatedRow values

                const gross = roundNumber(
                    item.salePrice * item.quantity,
                    roundingRule,
                    roundingPrecision + 1,
                );
                const totalTaxable = roundNumber(
                    gross - calculatedDiscount,
                    roundingRule,
                    roundingPrecision + 1,
                );
                const vat = roundNumber(
                    totalTaxable * item.tax_perc,
                    roundingPrecision,
                    roundingRule,
                );
                const total = roundNumber(
                    totalTaxable + vat,
                    roundingPrecision,
                    roundingRule,
                );

                // Calculate discount percentage
                const discountPercentage = roundNumber(
                    calculatedDiscount / gross,
                    roundingPrecision,
                    roundingRule,
                );

                return {
                    ...item,
                    discount: calculatedDiscount,
                    vat_amt: vat,
                    net_amount: total,
                    gross_amount: gross,
                    discount_perc: discountPercentage,
                    total_taxable_amount: totalTaxable,
                };
            });

            // Update the total discount value state
            setTotalDiscountValue(value);

            // Update the dataSource state with the new discount values
            setDataSource(newData);

            try {
                const payload = {
                    sales_details: newData.map(item => ({
                        ...item,
                        sales_code: text.toString(),
                        detail_code: item.key.toString(),
                        product: item.product.id,
                        product_name: item.product.name,
                        product_code: item.product.code,
                        gross_amount: item.gross_amount,
                        tax_amount: item.vat_amt,
                        net_amount: item.net_amount,
                        total_taxable_amount: item.total_taxable_amount,
                        tax_1: item.tax_1,
                        tax_2: item.tax_2,
                        multipack_id: item.multipack[0].id,
                        multipack_code: item.multipack[0].code,
                        multipack_name: item.multipack[0].name,
                        base_multipack_id: item.multipack[0].base_multipack_id,
                        base_multipack_code:
                            item.multipack[0].base_multipack_code,
                        base_multipack_name:
                            item.multipack[0].base_multipack_name,
                        multiplier: item.multipack[0].multiplier,
                        discount_perc: item.discount_perc,
                        discount_amount: item.discount ? item.discount : 0,
                    })),
                };

                // Remove unnecessary fields from the payload
                payload.sales_details.forEach(item => {
                    delete item.multipack;
                    delete item.salesType;
                    delete item.transactionType;
                    delete item.quantity;
                    delete item.key;
                    delete item.total;
                    delete item.vat;
                    delete item.discount;
                    delete item.tax_perc;
                });

                // Make the API call
                const response = await postApiWithAuthToken(
                    '/api/v1/admin/salesDetails/draft',
                    payload,
                );
            } catch (error) {}
        };

        return (
            <>
                <Table.Summary>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            Sales Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    isNaN(totalSales) ? 0 : totalSales,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2} align='end'>
                            Total Discount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            {editingDiscount ? (
                                <Text strong>
                                    {roundNumber(
                                        isNaN(totalDiscounValue)
                                            ? 0
                                            : totalDiscounValue,
                                        roundingRule,
                                        roundingPrecision + 1,
                                    )}
                                </Text>
                            ) : (
                                <Tooltip
                                    title={`Max total discount is ${dataSource.reduce((total, item) => total + (item.max_discount ? item.max_discount : 0), 0)}`}
                                >
                                    <Input
                                        value={totalDiscounValue}
                                        onChange={e =>
                                            handleTotalDiscountChange(
                                                e.target.value,
                                            )
                                        }
                                    />
                                </Tooltip>
                            )}
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            Total Taxable Amt.
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    isNaN(totalVat) ? 0 : totalVat,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            Total Tax Amt.
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    isNaN(totalTax) ? 0 : totalTax,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2} align='end'>
                            Net Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    isNaN(totalNetAmount) ? 0 : totalNetAmount,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
            </>
        );
    };

    return (
        <div>
            <Button
                style={{border: 0}}
                onClick={() => {
                    history.push(`sales-summary`);
                }}
            >
                <LeftOutlined />
            </Button>
            <Form
                form={form}
                name='new-sale-entry-form'
                onFinish={onFinish}
                layout='inline'
                style={{width: '100%'}} // Ensure form takes full width
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Descriptions
                        title={
                            <div style={{textAlign: 'left'}}>Add New Sale</div>
                        }
                        bordered
                        style={{margin: '8px', width: '100%'}}
                        column={{
                            xxl: 3,
                            xl: 3,
                            lg: 3,
                            md: 3,
                            sm: 2,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label='Invoice No'>
                            {text}
                        </Descriptions.Item>
                        <Form.Item name='date' label='Date'>
                            <DatePicker
                                value={moment()}
                                disabled
                                style={{pointerEvents: 'none'}} // Disable pointer events to prevent interaction
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}
                            />
                        </Form.Item>
                        {customerField}
                        <Form.Item
                            name='route_id'
                            label='Route'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a route',
                                },
                            ]}
                        >
                            <Select
                                style={{width: '200px'}}
                                showSearch
                                placeholder='Select Route'
                                optionFilterProp='children'
                                filterOption={(input, option) => {
                                    const childrenValue =
                                        option?.children
                                            ?.toString()
                                            .toLowerCase() || '';
                                    return (
                                        childrenValue.indexOf(
                                            input.toLowerCase(),
                                        ) >= 0
                                    );
                                }}
                                disabled={confirm}
                                onSelect={handleRouteSelect}
                            >
                                {routeDet.map(route => (
                                    <Select.Option
                                        key={route.id}
                                        value={route.id}
                                        disabled={route.available !== true}
                                    >
                                        {route.available !== true ? (
                                            <Tooltip
                                                title='Currently live routes are disabled'
                                                color='geekblue'
                                            >
                                                <span>{route.name}</span>
                                            </Tooltip>
                                        ) : route.code ? (
                                            `${route.code}_${route.name}`
                                        ) : (
                                            route.name
                                        )}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item name='salesman_id' label='Salesman'>
                            <Select
                                style={{width: '200px'}}
                                showSearch
                                placeholder='Select Salesman'
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={!route}
                                onSelect={salesmanId => {
                                    const selectedSalesman = salesman.find(
                                        s => s.id === salesmanId,
                                    );
                                    setSelectedSalesman(selectedSalesman);
                                }}
                            >
                                {salesman.map(salesman => (
                                    <Select.Option
                                        key={salesman.id}
                                        value={salesman.id}
                                    >
                                        {salesman.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name='currency_id' label='Currency'>
                            <Select
                                style={{width: '200px'}}
                                showSearch
                                placeholder='Select Currency'
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectedCurrency} // Set initial value
                                onSelect={currencyId => {
                                    const selectedCurrency = currencyDet.find(
                                        currency => currency.id === currencyId,
                                    );
                                    setSelectedCurrency(currencyId); // Update selected currency ID
                                    setSelectedCurrencyName(
                                        selectedCurrency.name,
                                    ); // Update selected currency name
                                    setSelectedCurrencyCode(
                                        selectedCurrency.code,
                                    ); // Update selected currency code
                                }}
                            >
                                {currencyDet.map(currency => (
                                    <Select.Option
                                        key={currency.id}
                                        value={currency.id}
                                    >
                                        {currency.name} ({currency.symbol})
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {transTypeField}
                        <Descriptions.Item label='Tax No.'>
                            {vatRegNo}
                        </Descriptions.Item>

                        <Form.Item name='remarks' label='Remarks'>
                            <TextArea
                                disabled={confirm}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                }}
                                value={remarks}
                                onChange={e => setRemarks(e.target.value)}
                            />
                        </Form.Item>
                    </Descriptions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            marginTop: '20px',
                        }}
                    >
                        {!confirm && customer && route && transName && (
                            <Button
                                type='primary'
                                onClick={() => {
                                    // setConfirm(true);
                                    form.submit();
                                }}
                            >
                                Confirm and Proceed
                            </Button>
                        )}
                    </div>
                </div>
            </Form>
            <div style={{marginTop: 16}}>
                <Row gutter={[16, 16]} justify='end'>
                    {showTable && (
                        <Col>
                            <Button
                                type='primary'
                                style={{
                                    marginRight: 8,
                                    border: '1px solid black',
                                    // backgroundColor: '#1890ff',
                                    color: 'white',
                                }}
                                onClick={FinalSubmit}
                                onMouseEnter={e =>
                                    (e.currentTarget.style.backgroundColor =
                                        '#40a9ff')
                                }
                                onMouseLeave={e =>
                                    (e.currentTarget.style.backgroundColor =
                                        '#1890ff')
                                }
                            >
                                Confirm
                            </Button>
                        </Col>
                    )}
                    {editable && confirm && (
                        <Col>
                            <Button
                                type='default'
                                style={{
                                    marginBottom: 16,
                                    marginLeft: 10,
                                    width: '180px',
                                }}
                                onClick={() => {
                                    setConfirm(false);
                                    // handleRemoveData();
                                }}
                            >
                                Edit Customer or Route
                            </Button>
                        </Col>
                    )}
                    {confirm && (
                        <Col>
                            <Button
                                onClick={handleAdd}
                                type='default'
                                style={{
                                    marginBottom: 16,
                                    marginLeft: 20,
                                }}
                                disabled={grSelected}
                            >
                                Add Product
                            </Button>
                        </Col>
                    )}
                    {grSelected && (
                        <Col>
                            <Button
                                type='primary'
                                style={{
                                    marginBottom: 16,
                                    marginLeft: 20,
                                }}
                                onClick={resetGr}
                            >
                                Reset
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>

            {showTable && (
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columnss}
                    pagination={false}
                    style={{marginBottom: '10px', marginTop: '50px'}}
                    summary={dataSource ? summaryRow : []}
                />
            )}

            {/* <Table summary={summaryRow} style={{marginTop: '10px'}} /> */}

            {/* modal window for productSelect */}
            <Modal
                open={productModal}
                onCancel={() => {
                    handleProductModalclose();
                    setSearchInput('');
                    setFilterOption('name'); // Reset filter option
                }}
                footer={null}
                style={{top: '10%'}} // Adjust the top value as needed
            >
                <h2>Product List</h2>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Input
                        placeholder={`Search By ${filterOption} ....`}
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        style={{marginRight: 10}}
                        ref={inputRef}
                    />
                    <Select
                        value={filterOption}
                        onChange={value => setFilterOption(value)}
                        style={{marginRight: 10}}
                    >
                        <Select.Option value='name'>Name</Select.Option>
                        <Select.Option value='code'>Code</Select.Option>
                        <Select.Option value='category'>Category</Select.Option>
                    </Select>
                    <Button
                        onClick={() => {
                            setSearchInput('');
                            setFilterOption('name'); // set default as name
                        }}
                    >
                        Reset
                    </Button>
                </div>
                <div style={{maxHeight: 300, overflowY: 'scroll'}}>
                    {productList
                        .filter(product => {
                            const filterValue =
                                filterOption === 'name'
                                    ? product.name
                                    : filterOption === 'code'
                                      ? product.code
                                      : product.category;

                            // Check if filterValue is a string before calling toLowerCase()
                            if (typeof filterValue === 'string') {
                                return filterValue
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase());
                            }
                            // If filterValue is not a string, don't include it in the filtered list
                            return false;
                        })
                        .map(product => (
                            <div
                                key={product.id}
                                style={{
                                    marginBottom: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid #adbab8',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    margin: '5px',
                                }}
                            >
                                <span>
                                    {product.name} ({product.code}) -{' '}
                                    {product.category}
                                </span>
                                <Button
                                    type='primary'
                                    onClick={() =>
                                        handleProductSelect(product.id)
                                    }
                                    style={{marginLeft: 10}}
                                >
                                    Add
                                </Button>
                            </div>
                        ))}
                </div>
            </Modal>

            <Modal
                open={collectionModalVisible}
                title={
                    <div style={{textAlign: 'center'}}>Collection Payment</div>
                }
                onCancel={() => setCollectionModalVisible(false)}
                footer={null}
            >
                <Form
                    name='dynamic_form_nest_item'
                    autoComplete='off'
                    onFinish={onFix}
                    style={{maxWidth: 600}}
                >
                    <Form.List name='collection' initialValue={[{}]}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map(
                                    (
                                        {key, name, fieldKey, ...restField},
                                        index,
                                    ) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align='baseline'
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'paymentType']}
                                                fieldKey={[
                                                    fieldKey,
                                                    'paymentType',
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Missing payment type',
                                                    },
                                                ]}
                                                style={{marginTop: '10px'}}
                                            >
                                                <Select
                                                    placeholder='Select payment type'
                                                    onChange={value =>
                                                        handleSelectChange(
                                                            value,
                                                            index,
                                                        )
                                                    }
                                                    style={{width: 200}}
                                                >
                                                    {paymentTypes.map(type => (
                                                        <Option
                                                            key={type.id}
                                                            disabled={selectedPaymentTypes.includes(
                                                                type.id,
                                                            )}
                                                            value={type.id}
                                                        >
                                                            {type.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'amount']}
                                                fieldKey={[fieldKey, 'amount']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Missing amount',
                                                    },
                                                    {
                                                        validator(_, value) {
                                                            const currentEnteredAmount =
                                                                parseFloat(
                                                                    value,
                                                                ) || 0;
                                                            const totalEnteredAmounts =
                                                                totalAmounts.reduce(
                                                                    (
                                                                        total,
                                                                        amount,
                                                                        idx,
                                                                    ) =>
                                                                        total +
                                                                        (idx !==
                                                                        index
                                                                            ? amount
                                                                            : 0),
                                                                    0,
                                                                );

                                                            const remainingBalance =
                                                                initialTotalBalance -
                                                                totalEnteredAmounts;

                                                            if (
                                                                totalEnteredAmounts +
                                                                    currentEnteredAmount >
                                                                initialTotalBalance
                                                            ) {
                                                                return Promise.reject(
                                                                    `Total amount exceeds available balance. You can enter up to ${remainingBalance.toFixed(
                                                                        2,
                                                                    )}`,
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type='number'
                                                    placeholder='Amount'
                                                    style={{width: 120}}
                                                    onChange={e =>
                                                        handleAmountChange(
                                                            e.target.value,
                                                            index,
                                                        )
                                                    }
                                                />
                                            </Form.Item>

                                            <MinusCircleOutlined
                                                onClick={() =>
                                                    handleRemoveRows(
                                                        index,
                                                        remove,
                                                    )
                                                }
                                            />
                                        </Space>
                                    ),
                                )}
                                {paymentTypes.length !==
                                    selectedPaymentTypes.length && (
                                    <Form.Item>
                                        <Button
                                            type='dashed'
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Collection
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>

                    <Form.Item
                        label='Total Balance'
                        style={{
                            marginBottom: '10px',
                            textAlign: 'center',
                        }}
                    >
                        <Input
                            value={totalBalance.toFixed(2)}
                            disabled
                            style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                border: 'none',
                                padding: '4px',
                                textAlign: 'center',
                                width: '200px',
                            }}
                            size='small'
                        />
                    </Form.Item>

                    <Form.Item style={{textAlign: 'right'}}>
                        <Button
                            type='primary'
                            htmlType='submit'
                            disabled={totalBalance !== 0}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Sales Invoices'
                open={eInvoiceModalVisible}
                onCancel={handleEinvoiceModalCancel}
                footer={null}
                width='80%' // Adjust the width of the modal as needed
                maskClosable={false}
            >
                <SalesSummaryModal
                    selectedSalesType={selectedSalesType}
                    routeCode={routeCode}
                    customerID={customer?.id}
                    salesmanCode={selectedSalesman?.code}
                    setGrSalesDetails={setGrSalesDetails}
                    onCancel={handleEinvoiceModalCancel}
                    setDataSource={setDataSource}
                    setGrMultipackValues={setGrMultipackValues}
                    setSelectProductId={setSelectProductId}
                    setBillingReference={setBillingReference}
                    setTransName={setTransName}
                />
            </Modal>

            {confirmFinal && <InvoiceView Invoice={invoiceData} />}
            {collectionFinal && (
                <CollectionInvoiceView Invoice={collectionData} />
            )}
        </div>
    );
};

export default NewSaleEntry;
