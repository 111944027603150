import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {message} from 'antd';

const configForAuth = {
    headers: {
        'Content-Type': 'application/json',
    },
};

const configForPublicAuth = {
    headers: {
        'Content-Type': 'application/json',
        'x-public-token': process.env.REACT_APP_PUBLIC_AUTH_TOKEN,
    },
    withCredentials: true,
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    async config => {
        const token = window.localStorage.getItem('accessToken');
        if (token && isTokenValid(token)) {
            config.headers['x-auth-token'] = token;
            config.headers['ngrok-skip-browser-warning'] = true;
            config.headers['bypass-tunnel-reminder'] = true;
        } else {
            try {
                const {accessToken} = await refreshToken();
                if (accessToken) {
                    config.headers['x-auth-token'] = accessToken;
                }
            } catch (err) {
                console.log(err);
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

const isTokenValid = token => {
    try {
        const {exp} = jwtDecode(token);
        if (Date.now() >= exp * 1000) {
            return false;
        } else {
            return true;
        }
    } catch (e) {
        return false;
    }
};

const refreshToken = async () => {
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (!refreshToken || refreshToken == 'undefined') {
        throw Error('No refresh token found');
    }

    const res = await postApiWithPublicAuthToken(
        '/api/v1/admin/auth/refresh',
        {refresh_token: refreshToken},
        {
            showMessage: false,
        },
    );
    if (res.data) {
        localStorage.setItem('accessToken', res.data);
        return {accessToken: res.data.accessToken};
    }
};

export const getApiWithPublicAuthToken = async (url, options) => {
    return handleResponse(
        () =>
            axios.get(
                process.env.REACT_APP_BASE_URL + url,
                configForPublicAuth,
            ),
        {
            showLoading: false,
            showSuccess: false,
            ...options,
        },
    );
};

export const getDownloadApi = async url => {
    try {
        const response = await axios.get(url + '?download=true', {
            responseType: 'blob',
        });
        return response;
    } catch (error) {
        throw new Error('Failed to download');
    }
};

export const postApiWithPublicAuthToken = async (url, formData, options) => {
    return handleResponse(
        () =>
            axios.post(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForPublicAuth,
            ),
        {
            loadingMessage: 'Processing',
            ...options,
        },
    );
};

export const getApiWithAuthToken = async (url, options) => {
    return handleResponse(
        () => instance.get(process.env.REACT_APP_BASE_URL + url),
        {
            showLoading: false,
            showSuccess: false,
            ...options,
        },
    );
};

export const postApiWithAuthToken = async (url, formData, options) => {
    return handleResponse(
        () =>
            instance.post(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForAuth,
            ),
        {
            loadingMessage: 'Processing',
            ...options,
        },
    );
};

export const deleteApiWithAuthToken = async (url, formData, options) => {
    return handleResponse(
        () =>
            instance.delete(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForAuth,
            ),
        {
            loadingMessage: 'Processing',
            ...options,
        },
    );
};

export const updateApiWithAuthToken = async (url, formData, options) => {
    return handleResponse(
        () =>
            instance.put(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForAuth,
            ),
        {
            loadingMessage: 'Processing',
            ...options,
        },
    );
};

export const updateWithFileApiWithAuthToken = async (
    url,
    formData,
    options,
) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return handleResponse(
        () =>
            instance.put(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                config,
            ),
        {
            loadingMessage: 'Processing',
            ...options,
        },
    );
};

const handleResponse = async (fn, options) => {
    const {
        loadingMessage = 'Loading',
        showMessage = true,
        showLoading = true,
        showSuccess = true,
        showError = true,
    } = options ?? {};

    const key = Math.random().toString();

    if (showMessage && showLoading)
        message.loading({content: loadingMessage, key});

    try {
        const {data} = await fn();

        if (showMessage && showSuccess && !data?.error && data?.data) {
            message.success({content: data.data, key});
        }
        return data;
    } catch (err) {
        const {data} = err.response ?? {};
        if (showMessage && showError) {
            if (data?.error) {
                message.error({
                    content: data.error.message,
                    key,
                });
            } else {
                message.error({content: 'Cannot connect to server', key});
            }
        } else {
            message.destroy(key);
        }
        // Remove {} and error handling from other places
        throw data ?? {};
    }
};
