import axios from 'axios';
import {
    UPDATE_ONBOARDING_STATUS,
    UPDATE_COMPANY_SETTINGS,
    LOGIN_SUCCESS,
    USER_LOADED,
    LOGOUT,
} from './types';
import {getApiWithAuthToken, postApiWithPublicAuthToken} from 'api';

// Load user
export const loadUser = () => async dispatch => {
    try {
        const res = await getApiWithAuthToken(
            '/api/v1/admin/auth/userDetails',
            {
                showMessage: false,
            },
        );
        if (res.data) {
            dispatch({type: USER_LOADED, payload: res.data});
            return res.data;
        }
    } catch (error) {
        return error.response;
    }
};

// Login user
export const login = loginData => async dispatch => {
    try {
        const res = await postApiWithPublicAuthToken(
            '/api/v1/admin/auth/login',
            loginData,
        );
        if (res.data) {
            dispatch({type: LOGIN_SUCCESS, payload: res.data});
            await loadUser()(dispatch);
            return res.data;
        }
    } catch (error) {
        return error.response;
    }
};

// register
export const register = regData => async dispatch => {
    try {
        const res = await postApiWithPublicAuthToken(
            '/api/v1/admin/auth/register',
            regData,
        );
        return res.data;
    } catch (error) {
        return error.response;
    }
};

// Update company settings
export const updateCompanySettings = companySettings => async dispatch => {
    dispatch({type: UPDATE_COMPANY_SETTINGS, payload: companySettings});
};

// Update Onboarding Status
export const updateOnboardingStatus = onboardingStatus => async dispatch => {
    dispatch({type: UPDATE_ONBOARDING_STATUS, payload: onboardingStatus});
};

// Logout
export const logout = () => {
    return {type: LOGOUT};
};
