import moment from 'moment';

export function convertLocalToUTC(
    localDateTime,
    format = 'YYYY-MM-DD HH:mm:ss',
    type = 'datetime',
) {
    // Parse the local date/time using moment based on the provided format
    const localMoment = moment(localDateTime, format);

    // Determine the return type and format accordingly
    if (type === 'time') {
        // Return only the UTC time in HH:mm:ss format
        return localMoment.utc().format('HH:mm:ss');
    } else if (type === 'date') {
        // Return only the UTC date in YYYY-MM-DD format
        return localMoment.utc(true).format('YYYY-MM-DD');
    } else {
        // Return the full UTC date and time in YYYY-MM-DD HH:mm:ss format
        return localMoment.utc().format('YYYY-MM-DD HH:mm:ss');
    }
}
