import React, {useEffect, useState} from 'react';
import {Image} from '@react-pdf/renderer';
import qr from 'qrcode';

const QRCodeComponent = ({text}) => {
    const [qrCodeImage, setQRCodeImage] = useState('');

    useEffect(() => {
        const generateQRCode = async () => {
            try {
                const qrDataURL = await qr.toDataURL(text);
                setQRCodeImage(qrDataURL);
            } catch (error) {
                console.error('Error generating QR code:', error);
            }
        };

        generateQRCode();
    }, [text]);

    return (
        <Image
            src={qrCodeImage}
            style={{width: 90, height: 90, marginLeft: 20}}
        />
    );
};

export default QRCodeComponent;
