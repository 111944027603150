import React, {useEffect} from 'react';
import {pdf} from '@react-pdf/renderer';
import {saveAs} from 'file-saver';
import {useHistory} from 'react-router-dom';
import CollectionPrint from './CollectionPrint';

function CollectionInvoiceView({Invoice}) {
    const history = useHistory();

    useEffect(() => {
        if (Invoice) {
            generateAndDownloadPdf();
        }
    }, [Invoice]);

    const generateAndDownloadPdf = async () => {
        try {
            const blob = await pdf(
                <CollectionPrint InvoiceData={Invoice} />,
            ).toBlob();
            saveAs(blob, `C.${Invoice.collection_code}.pdf`);
            //   history.push("/sales-summary"); // Navigate after download
        } catch (error) {
            console.error('Error generating PDF:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    return null; // This component doesn't render anything visible
}

export default CollectionInvoiceView;
