import React from 'react';
import {createRoot} from 'react-dom/client';
import Routes from './routes';
import {Provider} from 'react-redux';
import store from './store';
import './global.less';
import {ConfigProvider} from 'antd';
const root = createRoot(document.getElementById('root'));

root.render(
    <ConfigProvider
        theme={{
            token: {
                // Seed Token
                colorPrimary: '#1cb2bb',
                fontFamily: 'Poppins-Regular',
                fontFamilyCode: 'Poppins-Bold',
                fontSizeHeading1: 40,
                colorTextBase: '#293b47',
            },
        }}
    >
        <Provider store={store}>
            <Routes />
        </Provider>
    </ConfigProvider>,
);
