import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

const Loading = () => {
    return (
        <div
            style={{
                background: '#FFFFFF80',
                position: 'relative',
                height: '100%',
                width: '100%',
                zIndex: '999',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                }}
            >
                <Spin
                    indicator={<LoadingOutlined style={{fontSize: 50}} spin />}
                />
            </div>
        </div>
    );
};

export default Loading;
