import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import {
    Image,
    Text,
    View,
    Page,
    Document,
    StyleSheet,
} from '@react-pdf/renderer';
import {Font} from '@react-pdf/renderer';
import QRCodeComponent from './Qrcode';
import FontRegular from '../../../assets/fonts/OpenSans-Bold.ttf';
import NoImage from '../../../assets/images/no-image.png';
import FontArabic from '../../../assets/fonts/Zain-Bold.ttf';
import {getApiWithAuthToken} from 'api';

const Invoice = ({InvoiceData,data}) => {
   
    const Logo = localStorage.getItem('psLogo');

    // Register Amiri font
    Font.register({
        family: 'Regular',
        src: FontRegular,
    });

    Font.register({
        family: 'Amiri',
        src: FontArabic,
    });

   

    const styles = StyleSheet.create({
        page: {
            fontSize: 11,
            paddingTop: 20,
            paddingLeft: 40,
            paddingRight: 40,
            lineHeight: 1.5,
            flexDirection: 'column',
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 1,
        },

        rowCustomer: {
            flexDirection: 'row',

            justifyContent: 'space-between',
        },
        leftText: {
            fontFamily: 'Regular',
            fontSize: 10,
            fontWeight: 'bold',
        },
        valueText: {
            fontFamily: 'Regular',
            fontSize: 10,
            marginLeft: 40, // Adjust the spacing as needed
        },

        rightText: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 10,
            fontWeight: 'bold',
            textAlign: 'right',
        },

        label: {
            fontFamily: 'Regular',
            fontWeight: 'bold',
            fontSize: 9,
            width: 50, // Set a fixed width for the labels
        },
        value: {
            fontFamily: 'Regular',
            fontSize: 9,
        },
        labelArabic: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 9,
            textAlign: 'right',
            paddingBottom: 0,
            width: 50,
        },

        table: {
            borderTopWidth: 2,
            borderRightWidth: 2,
            borderLeftWidth: 2,
            borderColor: 'black',
            marginTop: 10,
            marginBottom: 10,
        },
        tableAddition: {
            borderTopWidth: 2,
            borderRightWidth: 2,
            borderLeftWidth: 2,
            borderColor: 'black',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCell: {
            padding: 2,
            borderRightWidth: 1,
            borderBottomWidth: 1,
            borderColor: 'black',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableCellLast: {
            padding: 2,

            borderBottomWidth: 2,
            borderColor: 'black',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableCellLast: {
            padding: 2,

            borderBottomWidth: 1,
            borderColor: 'black',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableHeaderItem: {
            fontSize: 7,
            fontFamily: 'Regular',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        tableHeaderItemLarge: {
            fontSize: 6,
            fontFamily: 'Regular',
            fontWeight: 'bold',
        },
        headerCell: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        signatureContainer: {
            marginTop: 20,
        },

        hr: {
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            marginBottom: 2,
            width: 80,
        },
        Line: {
            borderBottomColor: 'black',
            borderBottomWidth: 2,
            marginBottom: 1,
            width: '100%',
        },

        spaceBetween: {
            flex: 1,
            flexDirection: 'row',

            justifyContent: 'space-between',
        },

        titleContainer: {flexDirection: 'row', marginTop: 24},

        logo: {width: 100, height: 50},

        reportTitle: {fontSize: 16, textAlign: 'center'},

        addressTitle: {fontSize: 11, fontStyle: 'bold'},

        invoice: {fontFamily: 'Regular', fontWeight: 'bold', fontSize: 9},
        invoiceH: {
            fontFamily: 'Regular',
            fontWeight: 'bold',
            fontSize: 12,
        },
        invoiceV: {
            fontFamily: 'Regular',
            fontWeight: 'bold',
            fontSize: 10,
        },
        invoice1: {
            fontWeight: 'bold',
            fontSize: 9,
            marginTop: 15,
            fontFamily: 'Regular',
        },
        textLast: {
            fontWeight: 'thin',
            fontSize: 7,
            paddingTop: 10,
            marginTop: 5,

            fontFamily: 'Regular',
        },

        invoiceNumber: {fontSize: 11, fontWeight: 'bold'},

        address: {fontWeight: 400, fontSize: 10},
        arabicTextTable: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 8,
            fontWeight: 'bold',
            alignContent: 'center',
            marginBottom: 6,
        },
        arabicTextTableLarge: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 8,
            fontWeight: 'bold',
            alignContent: 'center',
            marginBottom: 2.5,
        },
        arabicText: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 12,
            fontWeight: 'bold',
        },
        arabicTextLast: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 12,
            fontWeight: 'bold',
            borderRightWidth: 2,
            paddingLeft: 4,
            borderRightColor: 'black',
        },
        arabicBold: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 15,
            fontWeight: 'bold',
        },
        arabicText1: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 9,
            textAlign: 'right',
            paddingBottom: 0,
        },
        arabicText2: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 14,
            textAlign: 'right',
            paddingBottom: 0,
        },
        arabicTextS: {
            fontFamily: 'Amiri', // Use the desired font family here
            fontSize: 10,
            textAlign: 'right',
            paddingBottom: 0,
        },
        itemContent: {
            fontSize: 10,
            fontFamily: 'Regular',
            fontWeight: 'bold',
            paddingLeft: '3px',
        },
        boxContent: {
            fontSize: 10,
            fontFamily: 'Regular',
            fontWeight: 'bold',
            paddingBottom: 4,
        },

        headerItem: {
            fontSize: 10,
            fontFamily: 'Regular',
            fontWeight: 'bold',
            paddingLeft: '3px',
            borderBottom: '2px solid black',
            justifyContent: 'center',
        },
        headerItemBottom: {
            fontSize: 10,
            fontFamily: 'Regular',
            fontWeight: 'bold',
            paddingLeft: '3px',

            justifyContent: 'center',
        },
        borderRight: {
            borderRightWidth: 2,
            borderRightColor: 'black',
        },
        headerItem1: {
            fontSize: 10,
            fontWeight: 'bold',
            fontFamily: 'Regular',
            padding: '10px',

            height: 30,
        },
        headerItem2: {
            fontSize: 8,
            fontWeight: 'bold',
            fontFamily: 'Regular',
        },

        headerItemMain: {
            width: '50%',
            height: 40, // Set a fixed height for each row
            justifyContent: 'center',
        },
        headerItemValue: {
            fontSize: 10,
            fontWeight: 'bold',
            padding: '5px',
            borderBottom: '1px solid black',
            width: '50%',
            borderLeft: '1px solid black',
        },
        headerItemSub: {
            borderRight: '2px solid black',
        },
        theader: {
            fontFamily: 'Regular',
            fontSize: 12,
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },

        theaderMain: {
            fontFamily: 'Regular',
            fontSize: 15,
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        theader1: {
            marginTop: 20,
            fontSize: 10,
            marginBottom: 5,
            fontStyle: 'bold',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            width: '15%',
        },
        theader2: {flex: 2, borderRightWidth: 0, borderBottomWidth: 1},
        spaceBetween: {
            flex: 1,
            flexDirection: 'row',

            justifyContent: 'space-between',
        },

        tbody: {
            fontSize: 9,
            paddingTop: 4,
            paddingLeft: 7,
            flex: 1,
            borderColor: 'whitesmoke',
            borderRightWidth: 1,
            borderBottomWidth: 1,
        },

        total: {
            fontSize: 9,
            paddingTop: 4,
            paddingLeft: 7,
            flex: 1.5,
            borderColor: 'whitesmoke',
            borderBottomWidth: 2,
        },

        tbody2: {flex: 2, borderRightWidth: 1},
    });
    const salemanArabic = '  بائع';
    const officeArabic = 'مكتب';
    const simplifiedTax = 'فاتورة ضريبية ';
    const CustomerName = 'اسم الزبون';
    const InvoiceNo = 'رقم الفاتورة';
    const permentTerms = 'شروط الدفع';
    const SupplyDate = 'تاريخ العرض';
    const SalesType = 'نوع المبيعات';
    const PaymentType = 'نوع الدفع';
    const VatNo = 'ضريبة المضافة';
    const Addresss = 'العنوان';
    const Remarks = 'ملاحظات';
    const email = 'بريد إلكتروني';
    const InvIssueDate = 'تاريخ الفاتورة';
    const PageNo = '1 من 1 الصفحة';

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoiceH}>{data?.company_name}</Text>
                    <Text style={styles.invoiceV}>{data?.company_sector}</Text>
                    <Text style={styles.invoiceH}>CR:{data?.company_CR}</Text>
                    <Text style={styles.invoiceH}>
                        VAT:
                        {data?.company_VAT}
                    </Text>
                    <Text style={styles.invoice}>{data?.company_website}</Text>
                    <Text style={styles.invoice}>
                        Email:{data?.company_email}
                    </Text>
                    <View style={styles.row}>
                        <Text style={styles.label}>Salesman:</Text>
                        <Text style={styles.value}>
                            {data?.show_salesman_mobile && 4589549848}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Office:</Text>
                        <Text style={styles.value}>
                            {data?.show_office_contact && 123456}
                        </Text>
                    </View>
                </View>
                <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
                    <Text style={styles.arabicText2}>
                        {' '}
                        {data?.company_name_ar}
                    </Text>

                    <Text style={styles.arabicTextS}>
                        {data?.company_sector_ar}
                    </Text>

                    <Text style={styles.arabicTextS}>
                        {' '}
                        {data?.company_CR_ar}
                    </Text>

                    <Text style={styles.arabicText1}>
                        {' '}
                        {data?.company_vat_ar}
                    </Text>

                    <Text style={styles.invoiceV}>
                        {' '}
                        {data?.company_website}
                    </Text>

                    <View style={styles.row}>
                        <Text style={styles.invoice}>
                            {' '}
                            {data?.company_email}:{' '}
                        </Text>{' '}
                        <Text style={styles.arabicText1}> {email}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.invoice}>
                            {' '}
                            {data?.show_salesman_mobile && 32098309}{' '}
                        </Text>{' '}
                        <Text style={styles.arabicText1}> {salemanArabic}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.invoice}>
                            {data?.show_office_contact && 1782611}{' '}
                        </Text>{' '}
                        <Text style={styles.arabicText1}> {officeArabic}</Text>
                    </View>
                </View>
            </View>
        </View>
    );

    const TableHead = () => (
        <View
            style={{
                marginTop: 10,
                border: '1px solid black',
            }}
        >
            <View style={styles.theaderMain}>
                <Text style={{textAlign: 'center', paddingRight: 10}}>
                    TAX INVOICE
                </Text>
                <Text style={[styles.arabicBold, {textAlign: 'center'}]}>
                    {simplifiedTax}
                </Text>
            </View>
            <View style={{borderBottom: '1px solid black'}}></View>
            <View style={styles.rowCustomer}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={styles.leftText}>Customer</Text>
                    <Text style={styles.valueText}>
                        {InvoiceData?.customer_name}
                    </Text>
                </View>
                <Text style={styles.rightText}>{CustomerName}</Text>
            </View>
            <View style={{borderBottom: '1px solid black'}}></View>
            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Invoice Number</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    {/* Replace 'invoiceNumberValue' with the actual value */}
                    <Text style={{}}>{InvoiceData?.inv}</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {InvoiceNo}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>VAT no</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    {/* Replace 'invoiceNumberValue' with the actual value */}
                    <Text>{InvoiceData?.vat_reg_no || data?.company_vat}</Text>
                </View>
                <View
                    style={[styles.headerItem, styles.headerItemMain, styles]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {VatNo}
                    </Text>
                </View>
            </View>
            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Inv Issue Date</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    {/* Replace 'invoiceNumberValue' with the actual value */}
                    <Text>{InvoiceData?.sales_date}</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {InvIssueDate}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem1,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={{textAlign: 'center', alignSelf: 'center'}}>
                        Address
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem1,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>
                        {data?.company_name}
                    </Text>
                </View>
                <View style={[styles.headerItem1, styles.headerItemMain]}>
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {Addresss}
                    </Text>
                </View>
            </View>
            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Payment Terms</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>{InvoiceData?.trans_type}</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {permentTerms}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text></Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text></Text>
                </View>
                <View
                    style={[styles.headerItem, styles.headerItemMain, styles]}
                >
                    <Text
                        style={[styles.arabicText, {textAlign: 'center'}]}
                    ></Text>
                </View>
            </View>

            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Supply Date</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>{InvoiceData?.sales_date}</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {SupplyDate}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Sales Type</Text>
                </View>
                <View
                    style={[
                        styles.headerItem,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={{textAlign: 'center'}}>SALES</Text>
                </View>
                <View
                    style={[styles.headerItem, styles.headerItemMain, styles]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {SalesType}
                    </Text>
                </View>
            </View>
            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.headerItemBottom,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Payment Type</Text>
                </View>
                <View
                    style={[
                        styles.headerItemBottom,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>{InvoiceData?.trans_type}</Text>
                </View>
                <View
                    style={[
                        styles.headerItemBottom,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {PaymentType}
                    </Text>
                </View>
                <View
                    style={[
                        styles.headerItemBottom,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>Remarks</Text>
                </View>
                <View
                    style={[
                        styles.headerItemBottom,
                        styles.headerItemMain,
                        styles.borderRight,
                    ]}
                >
                    <Text>{InvoiceData?.remarks}</Text>
                </View>
                <View style={[styles.headerItemBottom, styles.headerItemMain]}>
                    <Text style={[styles.arabicText, {textAlign: 'center'}]}>
                        {Remarks}
                    </Text>
                </View>
            </View>
        </View>
    );

    // Define your table header with both Arabic and English names ..second table
    const TableHeader = () => (
        <View style={styles.tableRow}>
            <View style={[styles.tableCell, styles.headerCell, {flex: 1}]}>
                <Text style={styles.arabicTextTable}>رقم</Text>
                <Text style={styles.tableHeaderItem}>No</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, {flex: 2}]}>
                <Text style={styles.arabicTextTable}>رمز الصنف</Text>
                <Text style={styles.tableHeaderItemLarge}>Item Code</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, {flex: 3}]}>
                <Text style={styles.arabicTextTable}>اسم العنصر</Text>
                <Text style={styles.tableHeaderItem}>Item Name</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCell, {flex: 1}]}>
                <Text style={styles.arabicTextTable}>كمية</Text>
                <Text style={styles.tableHeaderItem}>Qty</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, {flex: 1}]}>
                <Text style={styles.arabicTextTable}>وحدة</Text>
                <Text style={styles.tableHeaderItem}>Unit</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCell, {flex: 3}]}>
                <Text style={styles.arabicTextTable}>السعر الاجمالي</Text>
                <Text style={styles.tableHeaderItem}>Gross Price</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCell, {flex: 3}]}>
                <Text style={styles.arabicTextTable}>السعر الصافي</Text>
                <Text style={styles.tableHeaderItem}>Net Price</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, {flex: 2}]}>
                <Text style={styles.arabicTextTable}>تخفيض</Text>
                <Text style={styles.tableHeaderItem}>Discount</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCell, {flex: 2}]}>
                <Text style={styles.arabicTextTable}>مبلغ الخط</Text>
                <Text style={styles.tableHeaderItem}>Line Amt</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCell, {flex: 1}]}>
                <Text style={styles.arabicTextTable}>ضريبة</Text>
                <Text style={styles.tableHeaderItemLarge}>VAT%</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, {flex: 3}]}>
                <Text style={styles.arabicTextTable}>قيمة الضريبة</Text>
                <Text style={styles.tableHeaderItem}>VAT Amt</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCell, {flex: 2}]}>
                <Text style={styles.arabicTextTableLarge}>مبلغ شامل</Text>
                <Text style={styles.tableHeaderItemLarge}>Amt Inc VAT</Text>
            </View>
            <View style={[styles.headerCell, styles.tableCellLast, {flex: 3}]}>
                <Text style={styles.arabicTextTable}>نوع المبيعات</Text>
                <Text style={styles.tableHeaderItem}>Sales Type</Text>
            </View>
        </View>
    );

    // Define your table rows with dynamic data
    // Define your table rows with dynamic data
    const TableRows = () => (
        <View>
            {InvoiceData?.sales_details?.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                    <View style={[styles.tableCell, {flex: 1}]}>
                        <Text style={styles.tableHeaderItem}>{index}</Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 2}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.product_code}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 3}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.product_name}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 1}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.multipack_quantity}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 1}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.base_multipack_code}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 3}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.sale_price}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 3}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.gross_amount}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 2}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.discount_amount}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 2}]}>
                        <Text style={styles.tableHeaderItem}>
                            {(
                                item.sale_price * item.multipack_quantity
                            ).toFixed(2)}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 1}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.tax_perc || 0.15}{' '}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 3}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.tax_amount}
                        </Text>
                    </View>
                    <View style={[styles.tableCell, {flex: 2}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.total_taxable_amount}
                        </Text>
                    </View>
                    <View style={[styles.tableCellLast, {flex: 3}]}>
                        <Text style={styles.tableHeaderItem}>
                            {item.sales_type}
                        </Text>
                    </View>
                </View>
            ))}
        </View>
    );

    const AdditionalInfo = () => (
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            {/* Address Box */}
            <View style={{flex: 1, marginRight: 10}}>
                <View
                    style={{
                        border: '1px solid black',
                        padding: 10,
                        height: 100,
                    }}
                >
                    <Text style={styles.boxContent}>
                        Please pay by cheque or transfer to:
                    </Text>
                    <Text style={{fontSize: 8, paddingTop: 4}}>
                        {data?.bank_name1}
                    </Text>
                    <Text style={{fontSize: 8}}>{data?.bank_account_no1}</Text>
                    <Text style={{fontSize: 8}}>{data?.bank_name2}</Text>
                    <Text style={{fontSize: 8}}>{data?.bank_account_no2}</Text>
                </View>
            </View>
            {/* Space for Barcode */}
            <View style={{flex: 1, marginRight: 10, marginTop: 10}}>
                <QRCodeComponent text={InvoiceData?.inv} />
            </View>
            {/* Table */}
            <View style={{flex: 2}}>
                <View style={styles.tableAddition}>
                    <View style={styles.tableRow}>
                        {/* Table Header */}
                        <View
                            style={{
                                flex: 3,
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text
                                    style={[styles.headerItem2, {padding: 3}]}
                                >
                                    Gross
                                </Text>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Text style={[styles.arabicTextLast]}>
                                        الإجمالي
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* Table Value */}
                        <Text
                            style={[
                                styles.tableCellLast,

                                {
                                    flex: 1,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black',
                                    fontSize: 8,
                                    fontWeight: 'bold',
                                    fontFamily: 'Regular',
                                    paddingLeft: 15,
                                },
                            ]}
                        >
                            {/* {data?.company_CR}  */}
                            {InvoiceData?.gross_amount}
                        </Text>
                    </View>
                    <View style={styles.tableRow}>
                        {/* Table Header */}
                        <View
                            style={{
                                flex: 3,
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text
                                    style={[styles.headerItem2, {padding: 3}]}
                                >
                                    Discount
                                </Text>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Text style={[styles.arabicTextLast]}>
                                        الخصم
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* Table Value */}
                        <Text
                            style={[
                                styles.tableCellLast,

                                {
                                    flex: 1,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black',
                                    fontSize: 8,
                                    fontWeight: 'bold',
                                    fontFamily: 'Regular',
                                    paddingLeft: 15,
                                },
                            ]}
                        >
                            {/* {data?.company_CR} */}
                            {InvoiceData?.total_discount}
                        </Text>
                    </View>
                    `{' '}
                    <View style={styles.tableRow}>
                        {/* Table Header */}
                        <View
                            style={{
                                flex: 3,
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text
                                    style={[styles.headerItem2, {padding: 3}]}
                                >
                                    Total Taxable Amount
                                </Text>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Text style={[styles.arabicTextLast]}>
                                        المبلغ للضريبة
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* Table Value */}
                        <Text
                            style={[
                                styles.tableCellLast,

                                {
                                    flex: 1,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black',
                                    fontSize: 8,
                                    fontWeight: 'bold',
                                    fontFamily: 'Regular',
                                    paddingLeft: 15,
                                },
                            ]}
                        >
                            {/* {data?.company_CR}{' '} */}
                            {InvoiceData?.total_taxable_amount}
                        </Text>
                    </View>
                    <View style={styles.tableRow}>
                        {/* Table Header */}
                        <View
                            style={{
                                flex: 3,
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text
                                    style={[styles.headerItem2, {padding: 3}]}
                                >
                                    VAT
                                </Text>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.headerItem2,
                                            styles.arabicTextLast,
                                        ]}
                                    >
                                        القيمة المضافة
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* Table Value */}
                        <Text
                            style={[
                                styles.tableCellLast,

                                {
                                    flex: 1,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black',
                                    fontSize: 8,
                                    fontWeight: 'bold',
                                    fontFamily: 'Regular',
                                    paddingLeft: 15,
                                },
                            ]}
                        >
                            {/* {data?.company_CR} */}
                            {InvoiceData?.tax_amount}
                        </Text>
                    </View>
                    <View style={styles.tableRow}>
                        {/* Table Header */}
                        <View
                            style={{
                                flex: 3,
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text
                                    style={[styles.headerItem2, {padding: 3}]}
                                >
                                    Total
                                </Text>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.headerItem2,
                                            styles.arabicTextLast,
                                        ]}
                                    >
                                        المجموع
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* Table Value */}
                        <Text
                            style={[
                                styles.tableCellLast,

                                {
                                    flex: 1,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black',
                                    fontSize: 8,
                                    fontWeight: 'bold',
                                    fontFamily: 'Regular',
                                    paddingLeft: 15,
                                },
                            ]}
                        >
                            {/* {data?.company_CR} */}
                            {InvoiceData?.net_amount}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );

    const Signature = () => (
        <View style={styles.signatureContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    {/* Small HR for customer signature */}
                    <View style={styles.hr} />
                    {/* Arabic name for customer signature */}
                    <Text style={styles.arabicText}>توقيع العميل</Text>
                    {/* Customer signature */}
                    <Text style={styles.invoice1}>Customer Signature:</Text>
                </View>
                {/* Right end */}
                <View>
                    {/* Small HR for salesman signature */}
                    <View style={styles.hr} />
                    {/* Arabic name for salesman signature */}
                    <Text style={styles.arabicText}>توقيع البائع</Text>
                    {/* Salesman signature */}
                    <Text style={styles.invoice1}>Salesman Signature</Text>
                    <Text style={styles.textLast}>
                        {InvoiceData?.customer_name}
                    </Text>
                    <Text style={styles.textLast}>
                        Route Code: {InvoiceData?.route_code}
                    </Text>
                </View>
            </View>
        </View>
    );

    // Combine header and rows to form the table
    const DynamicTable = () => (
        <View style={styles.table}>
            <TableHeader />
            <TableRows />
        </View>
    );
    const Footer = () => (
        <View
            style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                textAlign: 'center',
                fontSize: 8,
                fontFamily: 'Regular',
                fontWeight: 'bold',
                padding: 10,
                borderTop: '2px solid black',
            }}
        >
            <View style={styles.spaceBetween}>
                <View>
                    <Text>Page 1 of 1</Text>
                </View>
                <View>
                    <Text>
                        {InvoiceData?.sales_date}
                        &nbsp;{' '}
                        {moment
                            .utc(InvoiceData?.sales_time, 'HH:mm:ss')
                            .local()
                            .format('HH:mm:ss')}
                    </Text>
                </View>
                <View>
                    <Text style={styles.arabicText}>{PageNo}</Text>
                </View>
            </View>
        </View>
    );

    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <Address />
                <View style={styles.Line} />
                {/* <UserAddress/> */}
                <TableHead />
                {/* <TableBody/> */}
                {/* <TableTotal /> */}
                <DynamicTable />
                <AdditionalInfo />
                <Signature />
                <Footer />
            </Page>
        </Document>
    );
};

export default Invoice;
