import React, {Suspense, useEffect, useState} from 'react';
import SiderView from './SiderView';
import {connect} from 'react-redux';
import HeaderView from './HeaderView';
import {Layout} from 'antd';
import {Outlet} from 'react-router-dom-v5-compat';
import Loading from './Loading';

const {Content} = Layout;

const ContentView = ({disableSider = false, children}) => {
    const [siderVisible, setSiderVisible] = useState(window.innerWidth > 500);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Layout
            style={{
                height: '98vh',
                overflow: 'hidden',
                scrollbarWidth: 'none',
            }}
        >
            <HeaderView
                disableSider={disableSider}
                toggleSider={() => {
                    setSiderVisible(!siderVisible);
                }}
            />
            <Layout>
                {!disableSider && (
                    <SiderView
                        toggleCollapsed={siderVisible}
                        isMobile={isMobile}
                    />
                )}

                <Content
                    style={{
                        padding: '24px',
                        overflow: 'auto',
                        height: '90vh',
                        scrollbarWidth: 'none',
                        zIndex: 1,
                    }}
                >
                    <Suspense fallback={<Loading />} s>
                        <Outlet />
                    </Suspense>
                    {/* {children} */}
                </Content>
            </Layout>
        </Layout>
    );

    // return (
    //   <Layout>
    //     <HeaderView setSiderVisible={setSiderVisible} siderVisible={siderVisible} />
    //     <Layout style={{position: 'relative'}}>
    //       {siderVisible && <SiderView width={200} />}
    //       <Layout>
    //         <Content style={{padding: 24, margin: 0, minHeight: '100vh'}}>
    //           {/* {children} */}
    //           <Outlet />
    //         </Content>
    //       </Layout>
    //     </Layout>
    //   </Layout>
    // );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, null)(ContentView);
