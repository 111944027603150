import {
    LOGIN_SUCCESS,
    USER_LOADED,
    UPDATE_ONBOARDING_STATUS,
    UPDATE_COMPANY_SETTINGS,
    LOGOUT,
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    user: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('refreshToken', action.payload.refresh_token);
            localStorage.setItem('accessToken', action.payload.access_token);
            return {
                ...state,
                isAuthenticated: true,
            };
        case UPDATE_ONBOARDING_STATUS:
            return {
                ...state,
                user: {...state.user, onboarding_status: action.payload},
            };
        case UPDATE_COMPANY_SETTINGS:
            return {
                ...state,
                user: {...state.user, company_settings: action.payload},
            };
        case LOGOUT:
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        default:
            return state;
    }
};

export default authReducer;
